import { useRef } from "react"
import { getUserProjects } from "../../../utils/LocalStorageUtils"
import { auto } from "@popperjs/core"
import { useAuth } from "../../modules/auth"
import { Error404 } from "../../modules/errors/components/Error404"

function DealFlowWrapper() {
      const iframeRef = useRef<HTMLIFrameElement>(null)
      const {auth} = useAuth()

      const projects = (getUserProjects('userProjects') || []).filter(project => project.type?.toLowerCase().includes("deal"))
      if (projects && projects.length > 0) {
        return (
            <div className='row m-0'>
                  <div className='card'>
                    <div className='card-body p-0' style={{ scrollbarWidth: 'none' }}>
                      <div className='row' style={{overflow: 'hidden', height: "calc(100vh - 110px - 45px)"}}>
                        <iframe
                          ref={iframeRef}
                          src={`${process.env.REACT_APP_TASKMANAGER_CLIENT_URL}/invPortal/myTasks/0/${projects[0].type}/${auth!}`}
                          title='Task Manager'
                          loading='eager'
                          width='100%'
                          style={{ border: 'none', height: '100%', padding: 0, overflow: auto}} // Fallback height if 0
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
        )
    }
    else {
        return (<Error404 />)
    }
}

export default DealFlowWrapper