import { useEffect, useRef } from 'react'
import { CompanyObject } from '../../../../../generated/graphql'
import { getAuth } from '../../../../modules/auth/core/AuthHelpers'

type Props = {
  companyData: CompanyObject
}

export const TasksViewWrapper = ({ companyData }: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null) // Create iframe ref
  const iframeWindowRef = useRef<Window | null>(null) // Persistent reference for iframe contentWindow
  const auth = getAuth()

  useEffect(() => {
    if (iframeRef.current) {
      iframeWindowRef.current = iframeRef.current.contentWindow
    }

    return () => {
      if (iframeWindowRef.current) {
        iframeWindowRef.current.postMessage(
          { type: 'UNMOUNT', message: 'Parent component is unmounting' },
          process.env.REACT_APP_TASKMANAGER_CLIENT_URL! // Target origin for security
        )
      }
    }
  }, [])

  return (
    <div className='row m-0'>
      <div className='card'>
        <div className='card-body p-0' style={{ scrollbarWidth: 'none' }}>
          <div className='row' style={{ overflow: 'hidden', height: 'calc(100vh - 110px - 45px)' }}>
            <iframe
              ref={iframeRef} // Attach the ref here
              src={`${process.env.REACT_APP_TASKMANAGER_CLIENT_URL}/invPortal/myTasks/${companyData.hissaCompanyId}/${auth!}`}
              title='Task Manager'
              loading='eager'
              width='100%'
              style={{ border: 'none', height: '100%', padding: 0, overflow: 'auto' }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
