import { Modal } from 'react-bootstrap'
import {
  GetGlobalCompaniesQuery,
  GlobalCompanySearchType,
  useGetGlobalCompaniesQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import _ from 'lodash'
import { downloadS3File } from '../../../../utils/ExportExcel'

type UploadCaptableProps = {
  showModal: boolean
  handleClose: () => void
  setStageType: Function
  isOpen: Function
  stageType: string
}
export function UploadCaptablePopup(props: UploadCaptableProps) {
  return (
    <Modal
      id='kt_modal_upload_captable'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered mw-550px px-6 `}
      show={props.showModal}
      onHide={() => {
        props.handleClose()
      }}
    >
      <div className='col d-flex justify-content-between px-9 '>
        <div className='py-9'>
          <h2 className='card-header fw-bolder text-dark'>{'Select a Preferred Option'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Body className='px-9 py-0'>
        <div className='card card-p-0 p-0'>
          <div className='card-body'>
            <div className='stepper stepper-links d-flex flex-column' id='kt_modal_add_inv_stepper'>
              <div className='w-100 '>
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col-12'>
                      <input
                        type='radio'
                        className='btn-check'
                        name='stageType'
                        value='stage_4'
                        id='kt_select_direct_transcation_method_direct'
                        onClick={() => {
                          props.setStageType('stage_4')
                          props.isOpen(false)
                        }}
                      />
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10
                        ${props.stageType === 'stage_4' && 'bg-primary bg-opacity-10'}`}
                        htmlFor='kt_select_direct_transcation_method_direct'
                      >
                        <img
                          src={toAbsoluteUrl('/media/icons/duotune/general/stage-4.svg')}
                          alt=''
                          className={`fs-4 fw-bold p-0 m-0 me-5 text-truncate cursor-pointer border rounded-circle col-3 ${
                            props.stageType === 'stage_4' ? 'border-primary' : 'border-gray-400'
                          }`}
                          style={{ maxWidth: '85px', maxHeight: '85px' }}
                        />

                        <span className='d-block fw-bold text-start col'>
                          <span className='text-dark fw-bolder d-block fs-4 '>
                            Upload Full Captable
                          </span>
                          <span className='text-gray-400 fw-bold fs-6 mb-2'>
                            Contains all historic transaction details of the company.
                          </span>
                          <div className='row d-flex justify-content-end align-items-end'>
                            <p
                              className='form-label pt-1 text-start mb-0 fs-7 text-hover-primary text-decoration-underline '
                              onClick={() => {
                                downloadS3File(
                                  'https://equion-dev.s3.us-west-2.amazonaws.com/Captable_Templates/Hissa_Captable_Template.xlsx'
                                )
                              }}
                            >
                              Download full Captable excel template
                              <img
                                src={toAbsoluteUrl('/media/svg/files/excel.svg')}
                                alt=''
                                className='fs-2 fw-bold p-0 ms-2 text-truncate cursor-pointer hover-effect'
                                style={{ maxWidth: '38px', maxHeight: '38px' }}
                                onClick={() => {
                                  downloadS3File(
                                    'https://equion-dev.s3.us-west-2.amazonaws.com/Captable_Templates/Hissa_Captable_Template.xlsx'
                                  )
                                }}
                              />
                            </p>
                          </div>
                        </span>
                      </label>
                    </div>

                    <div className='col-12'>
                      <input
                        type='radio'
                        className='btn-check'
                        name='stageType'
                        value='stage_3'
                        id='kt_select_indirect_transaction_method_indirect'
                        onClick={() => {
                          props.setStageType('stage_3')
                          props.isOpen(false)
                        }}
                      />
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center 
                        ${props.stageType === 'stage_3' && 'bg-primary bg-opacity-10'}`}
                        htmlFor='kt_select_indirect_transaction_method_indirect'
                      >
                        <img
                          src={toAbsoluteUrl('/media/icons/duotune/general/stage-3.svg')}
                          alt=''
                          className={`fs-4 fw-bold p-0 m-0 me-5 text-truncate cursor-pointer border rounded-circle col-3 ${
                            props.stageType === 'stage_3' ? 'border-primary' : 'border-gray-400'
                          }`}
                          style={{ maxWidth: '85px', maxHeight: '85px' }}
                        />

                        <span className='d-block fw-bold text-start col'>
                          <span className='text-dark fw-bolder d-block fs-4'>
                            Upload Current Captable
                          </span>
                          <span className='text-gray-400 fw-bold fs-6 mb-2'>
                            Contains latest transaction details of the company.
                          </span>
                          <div className='row'>
                            <p
                              className='form-label pt-1 text-start mb-0 fs-7 text-hover-primary text-decoration-underline '
                              onClick={() => {
                                downloadS3File(
                                  'https://equion-dev.s3.us-west-2.amazonaws.com/Captable_Templates/Current_Captable_Template.xlsx'
                                )
                              }}
                            >
                              Download current Captable excel template
                              <img
                                src={toAbsoluteUrl('/media/svg/files/excel.svg')}
                                alt=''
                                className='fs-2 fw-bold p-0 ms-2 text-truncate cursor-pointer hover-effect'
                                style={{ maxWidth: '38px', maxHeight: '38px' }}
                                onClick={() => {
                                  downloadS3File(
                                    'https://equion-dev.s3.us-west-2.amazonaws.com/Captable_Templates/Current_Captable_Template.xlsx'
                                  )
                                }}
                              />
                            </p>
                          </div>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='border border-0 p-9'>
        <div className='d-flex flex-stack'>
          <div className='mr-2'>
            <button
              onClick={() => props.isOpen(true)}
              type='button'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='previous'
            >
              Next
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
