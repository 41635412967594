import { useLocation } from 'react-router-dom'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

export function MenuInner() {
  let { pathname } = useLocation()
  const companyNameStore = useCompanyNameStore()
  pathname = pathname.toLowerCase().split('/')[1]
  type menuMappingType = { [key: string]: string }
  const asideMenuMapping: menuMappingType = {
    dashboard: 'Dashboard',
    portfolio: 'Portfolios',
    watchlist: 'Watchlist',
    deals: 'Deals',
    company: `${
      companyNameStore.companyName !== '' ? companyNameStore.companyName : 'Company Profile'
    } `,
    indirectcompanies: `${
      companyNameStore.companyName !== '' ? companyNameStore.companyName : 'Company Profile'
    } `,
    adduser: 'Add User',
    addinvestor: 'Add Investor',
    addaccount: 'Add Account',
    exitenabler: 'Exit Enabler',
    fundmetrics: 'Fund Metrics',
    mycaptables: 'My Cap Tables',
    privatedata: 'Private Data',
    usermanagement: 'User Management',
    noaccess: '',
    taskmanager: 'Projects',
    dealflow: 'Deal Flow',
  }
  const headerText =
    pathname in asideMenuMapping ? asideMenuMapping[pathname] : pathname.toUpperCase()

  return (
    <>
      <h1 className='d-flex align-items-center my-1 display-8 '>{headerText}</h1>
    </>
  )
}
