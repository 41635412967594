import _ from 'lodash'
import {
  AllTransactionsType,
  GetGlobalCompaniesQuery,
  GetNewWatchlistsQuery,
  GlobalCompanySearchType,
  WatchlistObject,
  useGetGlobalCompaniesQuery,
  useGetNewWatchlistsQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { AddWatchlist } from './Components/AddWatchlist'
import { EmptyWatchlistCard } from './Components/EmptyWatchlistCard'
import { WatchlistAGTable } from './Components/WatchlistAGTable'
import { WatchlistHeaderCard } from './Components/WatchlistHeaderCard'
import React, { useEffect, useState } from 'react'
import { WatchlistTickerCard } from './Components/WatchlistTickerCard'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import { globalCompaniesList } from '../../../utils/GlobalComapnies'
import { GlobalSearchBar } from './Components/GlobalSearchBar'
import { useLocation } from 'react-router-dom'

function WatchlistPageWrapper() {
  const [addWatchlist, setAddWatchlist] = useState<boolean>(false)
  const [searchOptions, setSearchOptions] = useState<boolean>(false)
  const [watchlists, setWatchlists] = useState<WatchlistObject[]>([])
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<GlobalCompanySearchType[]>([])
  const [currentWatchlist, setCurrentWatchlist] = useState<WatchlistObject>()
  const [watchlistTicker, setWatchlistTicker] = useState<any[]>([])
  const [tickerKey, setTickerKey] = useState(0)

  const [isPaused, setIsPaused] = useState(false)

  const location = useLocation()
  let existingWatchlist: any = location.state

  const restartTicker = () => {
    setTickerKey((prevKey) => prevKey + 1)
  }

  useEffect(() => {
    if (
      existingWatchlist === null &&
      watchlists.length > 0 &&
      data?.newWatchlists?.findIndex((watchlist) => watchlist?.name === currentWatchlist?.name) ===
        -1
    ) {
      setCurrentWatchlist(watchlists[0])
    }

    if (watchlists.length > 0 && existingWatchlist !== null) {
      const index = watchlists.findIndex(
        (watchlist) => watchlist.name === existingWatchlist.watchlistName
      )
      if (index > -1) {
        setCurrentWatchlist(watchlists[index])
      } else setCurrentWatchlist(watchlists[0])
    }

    if (watchlists.length === 0) {
      setCurrentWatchlist(undefined)
    }
  }, [existingWatchlist, watchlists])

  function getSearchedCompanies(search: string) {
    if (!search) {
      setFilteredCompanies([])
      return
    }

    const searchLower = search.toLowerCase()

    const startsWithCompanies = allCompanies.filter((company) => {
      const name = company.name?.toLowerCase() || ''
      const dba = company.dba?.toLowerCase() || ''
      return name.startsWith(searchLower) || dba.startsWith(searchLower)
    })

    const includesCompanies = allCompanies.filter((company) => {
      const name = company.name?.toLowerCase() || ''
      const dba = company.dba?.toLowerCase() || ''
      return (
        (name.includes(searchLower) && !name.startsWith(searchLower)) ||
        (dba.includes(searchLower) && !dba.startsWith(searchLower))
      )
    })

    const filteredResult = [...startsWithCompanies, ...includesCompanies]

    setFilteredCompanies(filteredResult)
  }

  const { data, isLoading } = useGetNewWatchlistsQuery<GetNewWatchlistsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const [isPageVisible, setIsPageVisible] = useState(true)

  const handleVisibilityChange = (isVisible: boolean) => {
    setIsPageVisible(isVisible)
  }

  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])

  useEffect(() => {
    if (data && data.newWatchlists) {
      setWatchlists(_.cloneDeep(data.newWatchlists) as WatchlistObject[])
      if (currentWatchlist) {
        const index = data?.newWatchlists?.findIndex(
          (watchlist: any) => watchlist.id === currentWatchlist?.id
        )
        if (index > -1) {
          currentWatchlist.starredCompanies = data?.newWatchlists?.[index]?.starredCompanies
          currentWatchlist.companies = data?.newWatchlists?.[index]?.companies
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (currentWatchlist && currentWatchlist.companies && currentWatchlist.companies?.length > 0) {
      const companyDataList: any[] = []
      const sortedWatchlist = [...currentWatchlist.companies].sort((a: any, b: any) => {
        const starredCompanies = currentWatchlist?.starredCompanies ?? []
        return (
          +starredCompanies.includes(b.companyData.hissaCompanyId) -
          +starredCompanies.includes(a.companyData.hissaCompanyId)
        )
      })
      sortedWatchlist.forEach((company: any) => {
        const companyData = {
          name: company.companyData.name,
          valuation: company.companyData.valuation,
          year: formatDate(company.companyData.latestRaiseDate) || '',
          logo: company.companyData.logo || '',
          companyId: company.companyData.hissaCompanyId,
        }
        companyDataList.push(companyData)
      })
      companyDataList.length >= 10
        ? setWatchlistTicker(companyDataList)
        : setWatchlistTicker(globalCompaniesList)

      restartTicker()
    }
    if (
      currentWatchlist &&
      currentWatchlist.companies &&
      currentWatchlist.companies?.length === 0
    ) {
      setWatchlistTicker(globalCompaniesList)
      restartTicker()
    }
  }, [currentWatchlist, data])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  function formatDate(dateString: string): string {
    const date = new Date(dateString) || new Date().toDateString()
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short' }
    return date.toLocaleDateString('en-US', options)
  }

  return (
    <>
      {/* Tickers */}
      {allCompanies.length > 0 && watchlistTicker.length > 0 && (
        <div className='row mb-8'>
          <div className='col-12'>
            <div
              className='card bg-transparent overflow-auto'
              style={{ overflowX: 'auto', scrollbarWidth: 'none' }}
            >
              <PageVisibility onChange={handleVisibilityChange}>
                {isPageVisible && (
                  <Ticker key={tickerKey} speed={5} move={isPaused ? false : true}>
                    {({ index }) => (
                      <div
                        key={index}
                        className='mt-4 card-body p-0 row flex-nowrap mx-0 mb-1'
                        onMouseEnter={() => setIsPaused(true)}
                        onMouseLeave={() => setIsPaused(false)}
                      >
                        {watchlistTicker.map((company, i) => (
                          <span
                            key={i}
                            style={{
                              display: index % watchlistTicker.length === i ? 'block' : 'none',
                            }}
                          >
                            <WatchlistTickerCard
                              companyId={company.companyId}
                              companyName={company?.name!}
                              valuation={company?.valuation!}
                              index={i}
                              logo={company.logo}
                              year={company.year}
                            />
                          </span>
                        ))}
                      </div>
                    )}
                  </Ticker>
                )}
              </PageVisibility>
            </div>
          </div>
        </div>
      )}
      {/* Global Search Bar*/}
      <div className='row mb-8'>
        <div className='col-12 d-flex justify-content-center'>
          <span
            className={`d-flex flex-column items-center bg-white w-full ${
              filteredCompanies.length > 0
                ? ' rounded position-absolute shadow-sm'
                : 'rounded rounded-pill'
            }`}
            style={{
              boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
              border: '1',
              borderColor: '#F1F1F2',
              zIndex: 6,
            }}
          >
            <span className='d-flex flex-row'>
              <KTSVG
                path={'media/icons/duotune/general/gen021.svg'}
                className='ps-4 svg-icon-2x text-start fs-9 pt-3'
                svgClassName='text-gray-700'
              />
              <input
                type='search'
                placeholder='Search'
                className='form-control w-350px w-lg-650px border-0 rounded rounded-pill'
                onClick={() => {
                  setSearchOptions(!searchOptions)
                }}
                onChange={(e) => {
                  getSearchedCompanies(e.target.value)
                }}
              />
            </span>
            <GlobalSearchBar
              filteredCompanies={filteredCompanies}
              currentWatchlist={currentWatchlist!}
            />
          </span>
        </div>
      </div>
      {/* Watchlist Tabs */}
      <div className='row mb-8'>
        <div className={`col-md-12 ${filteredCompanies.length > 0 ? 'pt-13' : ''}`}>
          {watchlists.map((watchlist, index) => (
            <span
              className={`border me-4 p-2 rounded rounded-pill bg-white border-1 ${
                currentWatchlist?.name === watchlist.name ? 'border-primary' : 'border-gray-400'
              }`}
              style={{ cursor: 'pointer' }}
              key={index}
              onClick={() => {
                // setTabName(watchlist.name || '')
                setCurrentWatchlist(watchlist)
              }}
            >
              <div
                className={`badge text-wrap fs-7 m-3 ${
                  currentWatchlist?.name === watchlist.name ? 'text-primary' : 'text-gray-800'
                }`}
              >
                {watchlist.name}
              </div>
            </span>
          ))}
          {watchlists.length > 0 && (
            <span
              className={`m-2 p-2 px-0 border-bottom border-hover-primary border-1 ${
                addWatchlist ? 'border-primary' : ''
              }`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setAddWatchlist(!addWatchlist)
              }}
            >
              <div
                className={`badge text-wrap fs-7 m-3 ${
                  addWatchlist ? ' text-primary' : ' text-gray-800'
                }`}
              >
                {'+ New Watchlist'}
              </div>
            </span>
          )}
          {addWatchlist && (
            <AddWatchlist
              showModal={addWatchlist}
              handleClose={() => setAddWatchlist(false)}
              isEdit={false}
            />
          )}
        </div>
      </div>
      {/* Watchlist Starred Cards */}
      {currentWatchlist?.starredCompanies && currentWatchlist?.starredCompanies?.length > 0 && (
        <div className='row mb-8'>
          <div className='col-12'>
            {currentWatchlist?.starredCompanies &&
              currentWatchlist?.starredCompanies.length > 0 && (
                <h4 className='pb-2 fw-bold'>{`Starred Companies in ${currentWatchlist?.name}`}</h4>
              )}
            <div
              className='card bg-transparent overflow-auto'
              style={{ overflowY: 'hidden', overflowX: 'auto', scrollbarWidth: 'none' }}
            >
              <div className='card-body p-0 row flex-nowrap mx-0 mb-1 gap-9'>
                {currentWatchlist?.companies?.map(
                  (company, index) =>
                    currentWatchlist.starredCompanies?.includes(
                      company?.companyData.hissaCompanyId!
                    ) && (
                      <WatchlistHeaderCard
                        companyName={company?.companyData.name || ''}
                        className=''
                        initIndex={index + 1}
                        dba={company?.companyData.dba || ''}
                        logo={company?.companyData.logo || ''}
                        valuation={company?.companyData.valuation || 0}
                        sharePrice={company?.companyData.sharePrice || 0}
                      />
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Watchlist AG Table */}
      <div className='row mb-9'>
        {currentWatchlist?.companies && currentWatchlist?.companies.length > 0 && (
          <div className='col-12'>
            <WatchlistAGTable
              watchlistCompanies={
                currentWatchlist?.companies?.sort((a: any, b: any) =>
                  a.companyData.name.localeCompare(b.companyData.name)
                ) as AllTransactionsType[]
              }
              watchlistId={currentWatchlist?.id}
              watchlistName={currentWatchlist.name!}
              starredCompanies={currentWatchlist.starredCompanies as string[]}
            />
          </div>
        )}
        {currentWatchlist?.companies && currentWatchlist?.companies.length === 0 && (
          <div>
            <EmptyWatchlistCard
              watchlistId={currentWatchlist?.id}
              watchlistName={currentWatchlist?.name!}
            />
          </div>
        )}
        {!isLoading && currentWatchlist === undefined && (
          <div>
            <div
              className='card p-0 h-400px'
              style={{
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                border: '1',
                borderColor: '#F1F1F2',
              }}
            >
              <div className='card-body'>
                <div className='row justify-content-center  text-center m-4'>
                  <img
                    alt='no items'
                    src={toAbsoluteUrl('/media/custom-images/noWatchlist.svg')}
                    className='h-200px mb-6 mw-250 justify-content-center'
                  />
                  <h1>No Watchlist Found</h1>
                  <span className='fw-semibold fs-6'>Click below to create a new watchlist</span>
                  <div className='col justify-content-center pt-4'>
                    <button
                      className='btn btn-primary mt-4 mx-8'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_1'
                      onClick={() => {
                        setAddWatchlist(!addWatchlist)
                      }}
                    >
                      Add Watchlist
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default WatchlistPageWrapper
