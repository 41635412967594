import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  AddTagsToInvestmentMutation,
  AddTagsToInvestmentMutationVariables,
  CompanyMetadataType,
  CompanyObject,
  TransactionObject,
  useAddTagsToTransactionMutation,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import { InputBox } from '../../../../utils/NumberInputBox'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'

type Props = {
  notes: string
  handleClose: () => void
  showModel: boolean
  companyDetails: CompanyObject
  transactionDetails: TransactionObject
  hissaCompanyId: string
  investorId?: bigint
  tags?: string[]
  showModal: boolean
  setShow: Function
  investmentId?: bigint
  hissaRecordId?: string
  indirectInvestmentId?: bigint
  transactionCurrency?: string
  transactionAmount?: number
  noOfUnits?: number
}

export function TransactionNotes(props: Props) {
  const [notes, setNotes] = useState<string>(props.notes)
  const mutateAddNotesToTransaction = useAddTagsToTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddTagsToInvestmentMutation,
        _variables: AddTagsToInvestmentMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: {
            type: props.companyDetails.type,
            hissaCompanyId: props.companyDetails.hissaCompanyId || '',
            id: Number(props.companyDetails.id) || 0,
          },
        }
        const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)

        queryClient.invalidateQueries(getCompanyWiseQuery)
        Toast('Notes Updated Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast('Error updating Notes', ToastType.error)
      },
    },
    {}
  )

  function HandleSubmitNotes() {
    mutateAddNotesToTransaction.mutate({
      input: {
        companyType: props.companyDetails.type,
        hissaCompanyId: props.hissaCompanyId,
        companyId: props.companyDetails.id,
        investmentId: props.investmentId,
        hissaRecordId: props.hissaRecordId,
        indirectInvestmentId: props.indirectInvestmentId,
        companyName: props.companyDetails.name,
        tags: props.transactionDetails.tags,
        investorId: props.investorId,
        currency: props.transactionDetails.currency || props.transactionCurrency || 'INR - ₹',
        exchangeRate: 1,
        investedAmount: props.transactionDetails.amount || props.transactionAmount || 0,
        investmentDate: props.transactionDetails.transactionDate?.split('T')[0],
        noOfShares: props.transactionDetails.noOfShares || props.noOfUnits || 0,
        sharePrice: props.companyDetails.sharePrice || 1,
        jurisdiction: props.companyDetails.jurisdiction || 'India',
        securitySubType: props.transactionDetails.securitySubType || 'Others',
        notes: notes,
      },
    })
  }
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-750px'
      show={props.showModel}
      onHide={props.handleClose}
    >
      <div className='col d-flex justify-content-between px-9'>
        <div className='pt-9 pb-4'>
          <h2 className='card-header fw-bolder text-dark'>{'Notes'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <div className='separator mb-4'></div>
      <Modal.Body className='p-9 pt-0'>
        <textarea
          className='form-control me-2 custom-input fw-bold shadow-lg '
          placeholder='Enter Transaction Notes....'
          onChange={(e: any) => {
            setNotes(e.target.value)
          }}
          value={notes}
          style={{ minHeight: '350px' }}
        />

        <div className='col-12 d-flex justify-content-end mt-4'>
          <button
            type='button'
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
            onClick={() => {
              HandleSubmitNotes()
            }}
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
