import axios, { AxiosRequestHeaders } from "axios";
import { getAuth } from "../app/modules/auth";
import { checkTokenValidity } from "./validationgAuth";

const esignClient = axios.create({
    baseURL: process.env.REACT_APP_ESIGN_API_URL
})

esignClient.defaults.headers.post["Content-Type"] = "application/json";
esignClient.interceptors.request.use((config) => {
    const jwt = getAuth() as string;
    if(checkTokenValidity(jwt)){
        (config.headers as AxiosRequestHeaders).Authorization = jwt 
    }
    return config
})
export default esignClient;