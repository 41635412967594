import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import {
  DealTemplate,
  GetAllInvestorRoundModellingDataQuery,
  GetGlobalCompaniesQuery,
  GlobalCompanySearchType,
  useGetAllInvestorRoundModellingDataQuery,
  useGetGlobalCompaniesQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../../../utils/Select'
import InvModelSummaryCard from '../../../company/components/InvestmentModelling/InvModelSummaryCard'
import { useNavigate } from 'react-router-dom'
import Toast, { ToastType } from '../../../../../utils/toast'

type ImportInvestmentProps = {
  showModal: boolean
  handleClose: () => void
  templateData: DealTemplate
  allCompanies: GlobalCompanySearchType[]
  initialValues: any
}
export function ImportInvestmentModel({
  showModal,
  handleClose,
  allCompanies,
  templateData,
  initialValues,
}: ImportInvestmentProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const existedCompany =
    allCompanies &&
    allCompanies.filter((template: any) => template.name === initialValues?.companyName)
  const [currentCompany, setCurrentCompany] = useState<GlobalCompanySearchType>(
    existedCompany.length > 0 ? existedCompany[0] : ({} as GlobalCompanySearchType)
  )
  const navigate = useNavigate()

  const CompaniesList: SelectOptionType[] = []
  allCompanies?.forEach((Company: any) => {
    CompaniesList.push({
      value: Company.id,
      label: Company.name,
    })
  })

  const [company, setCompany] = useState<any>(
    Object.keys(currentCompany).length > 0
      ? {
          value: currentCompany.id,
          label: currentCompany.name,
        }
      : {
          value: '',
          label: '',
        }
  )

  const { data, isFetched } = useGetAllInvestorRoundModellingDataQuery<
    GetAllInvestorRoundModellingDataQuery,
    Error
  >(
    graphqlRequestClient,
    {
      input: { hissaCompanyId: currentCompany?.id },
    },
    {
      enabled: (currentCompany && currentCompany.id !== undefined) || false,
    }
  )
  const [selectedModel, setSelectedModel] = useState<any>()

  const investmentData = selectedModel?.investmentModellingData?.investmentData
  const totalInvestment = investmentData?.reduce(
    (acc: any, curr: any) => acc + curr.investmentAmount,
    0
  )

  const invModelList: SelectOptionType[] = []
  data?.getAllInvestorRoundModelling?.forEach((model: any) => {
    invModelList.push({
      value: model.id,
      label: model.name,
    })
  })

  function handleCreateTermsheet() {
    setLoading(true)
    const newTemplateData = {
      templateId: templateData.id,
      hissaCompanyId: currentCompany?.id,
      pricedRound: 'Yes',
      isRoundModel: true,
      companyName: currentCompany?.name || '',
      cin: currentCompany?.cin || '',
      investmentAmount: totalInvestment,
      ...selectedModel?.investmentModellingData?.roundData,
    }
    navigate('/contracts/draftTermsheet', { state: newTemplateData })
    Toast('Data Imported Successfully', ToastType.success)
    handleClose()
  }

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={showModal}
      onHide={() => handleClose()}
    >
      <Modal.Title>
        <h2 className='fw-bolder text-gray-heading p-9 mb-0 '>Investment Model</h2>
        <div className='separator'></div>
      </Modal.Title>
      <Modal.Body className='p-0'>
        <div className='card p-0 '>
          <div className='d-flex row m-9'>
            <div className='row mx-auto p-0'>
              <div className='fv-row p-0 col-12 col-md-6 pe-9'>
                <div className='fv-row mb-10 col-12'>
                  <label className='form-label'>Select Company</label>

                  <Select
                    options={CompaniesList}
                    value={company}
                    placeholder={'Select ...'}
                    onChange={(e: any) => {
                      setCompany(e)
                      const selectedCompany = allCompanies.filter(
                        (template: any) => template.name === e.label && template.id === e.value
                      )
                      if (selectedCompany.length > 0) setCurrentCompany(selectedCompany[0])
                      setSelectedModel(undefined)
                    }}
                    styles={selectCustomStyles}
                  />
                  <div className='text-danger mt-2'>
                    {company.value !== '' && invModelList.length === 0 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {'No model was found in the selected company'}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {invModelList && invModelList.length > 0 && (
                  <div className='fv-row mb-10 col-12'>
                    <label className='form-label'>Select Model</label>

                    <Select
                      options={invModelList}
                      onChange={(e: any) => {
                        const currentModel =
                          (data?.getAllInvestorRoundModelling &&
                            data?.getAllInvestorRoundModelling?.filter(
                              (template: any) =>
                                template.name === e.label && template.id === e.value
                            )) ||
                          []
                        if (currentModel.length > 0) setSelectedModel(currentModel[0])
                      }}
                      styles={selectCustomStyles}
                    />
                  </div>
                )}
              </div>
              {selectedModel && selectedModel?.id && (
                <div className='fv-row p-0 col-12 col-md-6'>
                  <InvModelSummaryCard modelData={selectedModel} />
                </div>
              )}
            </div>
            <div className='d-flex col justify-content-between pt-10 px-0'>
              <button
                className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                onClick={() => handleClose()}
              >
                <span className='indicator-label'>Cancel</span>
              </button>
              <button
                type='submit'
                className={`${
                  !selectedModel ? 'disabled bg-primary-light' : 'bg-primary'
                } px-4 rounded border border-0 border-primary  h-38px fw-normal text-white`}
                disabled={!selectedModel}
                onClick={() => handleCreateTermsheet()}
              >
                {'Confirm'}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
