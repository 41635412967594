// import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import { useEffect, useRef, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { useGetUrlForS3ObjectQuery, GetUrlForS3ObjectQuery, useSendNewTermsheetMutation, SendNewTermsheetMutation, SendNewTermsheetMutationVariables } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import axios from 'axios'
import SyncfusionDocumentEditor, { fetchDocumentFile } from '../../utils/SyncfusionDocumentEditor'
import {Buffer} from 'buffer'
import { convertBase64ToBlob } from '../../../../../utils/ExportExcel'
type Props = {
    termsheetData: any
    showModel: boolean
    handleClose: () => void
  }
function DealsDocumentEditor({ termsheetData, showModel, handleClose }: Props) {
    const [loading, setLoading] = useState(false)
    const [showWarnDialog,setShowWarnDialog] = useState(false)
    // let documentRef = useRef<DocumentEditorContainerComponent | null>(null)
    const updateDocument = useSendNewTermsheetMutation<Error>(
      graphqlRequestClient,
      {
        onSuccess: (
          data: SendNewTermsheetMutation,
          _variables: SendNewTermsheetMutationVariables,
          _context: unknown
        ) => {},
        onError: (error: any) => {},
      },
      {}
    )
    const {data,isFetched} = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
        graphqlRequestClient,
        {
          input: {
            key: termsheetData?.docURL,
            bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
          },
        },
        {
        }
      )
      const docPresignUrl = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
        graphqlRequestClient,
        {
          input: {
            key: termsheetData?.docURL,
            bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
            isUpload:true,
          },
        },
        {
        }
      )
      const pdfPresignUrl = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
        graphqlRequestClient,
        {
          input: {
            key: termsheetData?.fileURL,
            bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
            isUpload:true,
          },
        },
        {
        }
      )
      const fetchFileFromUrl = async (url: string) => {
        return await fetch(url)
          .then((response) => {
            return response.blob()
          })
          .catch((error) => {
            console.error('Fetch error:', error)
            return null
          })
      }
    
      useEffect(() => {
        if (data) {
          loadDocument(data)
        }
      }, [isFetched])
      const loadDocument = async (data:GetUrlForS3ObjectQuery)=>{
        const doc = await fetchDocumentFile(data?.urlForS3Object!)
        if(doc !== undefined){
          // documentRef.current?.documentEditor.open(JSON.stringify(doc))
        }
      }
      const NewInstance = axios.create({
        // Configuration
        baseURL: '',
        timeout: 8000,
        headers: {},
      })
      const save = async () => {
      //   const docxBlob = await documentRef.current?.documentEditor
      // .saveAsBlob('Docx')
      // .then((blob: Blob) => {
      //   return blob
      // })
    //   if (docxBlob) {
    //     const uploadFile = await NewInstance.put(
    //       docPresignUrl.data?.urlForS3Object || '',
    //       docxBlob
    //     )
    //   const arrayBuffer = await docxBlob.arrayBuffer();
    //   const file = Buffer.from(arrayBuffer);
    //   const pdfB64 = await axios.post(`${process.env.REACT_APP_API_URL}/docToPdf`,{file:file},{}).then((res)=>{
    //     return res.data.base64
    //   }).catch((err)=>{
    //     return err
    //   })
    //   const pdfBlob = await convertBase64ToBlob(pdfB64,'application/pdf')
    //   if (pdfBlob) {
    //   const uploadFile = await NewInstance.put(
    //     pdfPresignUrl.data?.urlForS3Object || '',
    //     pdfBlob
    //   )
    // }
    //   updateDocument.mutate({
    //     input:{fileUrl:termsheetData?.fileURL,docURL:termsheetData?.docURL,status:termsheetData.status,termsheetId:termsheetData.id},
    //     sendImmediately:false
    //   })
    // }
    // setLoading(false)
    // handleClose()
      }
  return (
    <Modal
    dialogClassName={`modal-dialog modal-dialog-centered mw-75`}
    show={showModel}
    onHide={() => {
      handleClose()
    }}
    style={{filter:`${showWarnDialog ? `blur(3px)`:""}`}}
  >
    <ModalHeader>
        <Modal.Title>Edit Generated Document</Modal.Title>
    <div className='row justify-content-end'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            disabled={showWarnDialog}
            onClick={() => {
              setShowWarnDialog(true)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
    </ModalHeader>
    <ModalBody>
      <>
      {/* <SyncfusionDocumentEditor documentName={termsheetData.termsheetName} documentReference={documentRef}/> */}
      <div className='mt-2 text-primary'>
          Note: Do not edit the words which are enclosed with # i.e(#investorName#)
        </div>
        <div>
            <button
              className='btn btn-secondary mt-5'
              disabled={showWarnDialog}
              onClick={() => {
                setShowWarnDialog(!showWarnDialog)
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary mt-5 float-end'
              onClick={()=>{
                  setLoading(true)
                  save()
              }}
              disabled={loading || showWarnDialog}
            >
              Save
            </button>
          </div></>
    </ModalBody>
    {showWarnDialog && (
    <Modal className='modal-dialog modal-dialog-centered w-50' centered show={showWarnDialog}>
    <Modal.Header className='p-4'>
      <Modal.Title><h1 className='text-warning'>Warning</h1></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <>
      <div><h3>Do you want to save the changes or discard it? </h3></div>
      <div>
          <button
            className='btn btn-secondary mt-5'
            onClick={() => {
              setShowWarnDialog(!showWarnDialog)
              handleClose()
            }}
          >
            Discard
          </button>
          <button
            className='btn btn-primary mt-5 float-end'
            onClick={()=>{
              setLoading(true)
                  save()
            }}
            disabled={loading}
          >
            Save
          </button>
        </div>
      </>
    </Modal.Body>
  </Modal>
  )}
  </Modal>
  )
}
export default DealsDocumentEditor