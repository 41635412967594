import axios from "axios";
import React, { useState, useEffect } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { GetUrlForS3ObjectQuery, SendNewTermsheetMutation, SendNewTermsheetMutationVariables, useGetDealTemplatesQuery, useGetTermsheetsQuery, useGetUrlForS3ObjectQuery, useSendNewTermsheetMutation } from "../../../../../generated/graphql";
import { graphqlRequestClient, queryClient } from "../../../../../queries/client";
import { getAuth, JwtPayload } from "../../../../modules/auth";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import Dropzone, { IPreviewProps, IInputProps, ILayoutProps } from "react-dropzone-uploader";
import convertToBase64 from "../../../../../utils/convertTobase64";
import { convertBase64ToBlob } from "../../../../../utils/ExportExcel";
import Toast, { ToastType } from "../../../../../utils/toast";
import { SignerData } from "../../wrappers/DocumentIssuePageWrapper";
import SignatureIframe from "./SignatureIframe";
import { SignatoriesStatus } from "./SignAndUploadModal";

type Props={
    documentData:any
    handleClose:Function
    signatoriesData?:SignatoriesStatus
}
export function SignStatus({documentData,signatoriesData}:Props) {
    const jwt = getAuth()
    const esignApi = process.env.REACT_APP_ESIGN_API_URL
    const [documentStatus, setDocumentStatus] = useState<string>()
    const [signerStatus, setSignerStatus] = useState<any>()
    const [isLoading,setIsLoading] = useState(false)
    useEffect(() => {
      if (signatoriesData !== undefined &&Object.keys(signatoriesData.signers).length > 0) {
                if((signatoriesData?.esignStatus as string).includes('_')){
                  const status = (signatoriesData?.esignStatus as string).split('_').join(" ")
                setDocumentStatus(status)
              }else{
                setDocumentStatus(signatoriesData?.esignStatus)
              }
              setSignerStatus(signatoriesData?.signers)
              }
    }, [signatoriesData])
    const handleSendRemainder=(emailId:string)=>{
      setIsLoading(true)
      const headers = {"Authorization": jwt !== undefined ? jwt : ""}
    axios.put(`${esignApi}/admin/sendReminder?esignId=${documentData.eSignId}`, {"signerId":emailId}, {headers})
    .then((data)=>{
      setIsLoading(false)
      Toast('Remainder mail sent Successfully!', ToastType.success)
    })
    .catch((err:any)=>{
    Toast(err.response.data.data.message, ToastType.warning)
    setIsLoading(false)
    })
    }
    return (
        <React.Fragment>
        <div className='d-flex'>
          <h4 className='fw-bold my-1'>Document Status :</h4>
          <div className={`badge ms-4 fw-bold text-capitalize ${documentStatus === "draft" ? 
            "text-info bg-light-info":documentStatus === "cancelled"?
            "text-danger bg-light-danger":documentStatus === "completed"?
            "text-success bg-light-success":"text-warning bg-light-warning"}`}>{documentStatus}</div>
        </div>
          <div className='border-bottom border-dark my-2'></div>
        <h4 className='fw-bold pt-2'>Signer's Status :</h4>
        <div className='ms-4 pt-2'>
              {signerStatus &&
  Object.entries(signerStatus).map((signer: any) => {
    return (
      <>
      {signer[0]===""? <h5 className=''>No Signer Data !</h5>:
      <div className='d-flex justify-content-between'>
        <div className="d-flex">
        <h5 className='align-self-center fw-bold w-300px m-0'>{signer[1]['email']}</h5>
        <div className={`badge p-3 my-2 ${
            signer[1]['status'] === 'signature_requested' ? 'text-warning bg-light-warning' : signer[1]['status'] === 'created'?"text-info bg-light-info":'text-success bg-light-success'
          }`}>{signer[1]['status'].toUpperCase().split('_').join(" ")}</div>
        </div>
      <button className={`btn btm-sm btn-primary p-1 mb-2`} onClick={()=>{handleSendRemainder(signer[1]['email'])}} disabled={signer[1]['status'] !== 'signature_requested' ||isLoading}>
      <KTSVG path="media/icons/duotune/general/gen007.svg" className="svg-icon-muted svg-icon-2hx m-0" svgClassName="mh-25px"/>
      </button>
      </div>
      }
      </>
    )
  })}
        </div>
      </React.Fragment>
    )
  }
export function RequestSign({documentData,handleClose,signatoriesData}:Props) {
    const jwt = getAuth()
    const esignApi = process.env.REACT_APP_ESIGN_API_URL
    const invApi = process.env.REACT_APP_API_URL
  const jwtDetails = jwtDecode<JwtPayload>(jwt!)
    const [optionType,setOptionType] = useState('genPdf')
    const [loading, setLoading] = useState(false)
    const [iFrameToken,setIFrameToken] = useState<string>('')
    const [eSignId,setESignId] = useState<string>('')
    const [signDialog,setSignDialog] = useState<boolean>(false)
    const [showWarnDialog,setShowWarnDialog] = useState(false)
    const [uploadFile,setUploadFile] = useState<any>()
    const [genPdfURL,setGenPdfURL] = useState('')
    const [timeStamp,setTimeStamp]= useState('')
    const [signDraftStatus,setSignDraftStatus] = useState(false)
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
      setIsLoading(true)
      if (signatoriesData !== undefined&&Object.keys(signatoriesData.signers).length > 0) {
        if(Object.keys(signatoriesData!.signers)[0]===""){
          setSignDraftStatus(true)
        }
      }
      setIsLoading(false)
      }, [signatoriesData])
    useEffect(()=>{
      const timestamp = new Date()
      .toISOString()
      .replace(/:/g, '-')
      .replace(/\.\d{3}/, '')
      setTimeStamp(timestamp)
    },[])
  
      const uploadPdfUrl = 'investor/' + documentData?.accountId + '/contracts/document_upload-' + timeStamp + '.pdf'
      const signedPdfUrl = 'investor/' + documentData?.accountId + '/contracts/document_signed-' + timeStamp + '.pdf'
      const uploadURL = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
        graphqlRequestClient,
        {
          input: {
            key: uploadPdfUrl,
            bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
            isUpload:true
          },
        },
        {
          enabled:uploadPdfUrl !== ""
        }
      )
      const signedURL = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
        graphqlRequestClient,
        {
          input: {
            key: signedPdfUrl,
            bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
            isUpload:true
          },
        },
        {
          enabled:signedPdfUrl !== ""
        }
      )
    const {data, isFetched} = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
        graphqlRequestClient,
        {
          input: {
            key: documentData?.fileURL,
            bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
          },
        },
        {
          enabled:documentData?.fileURL !== "",
        }
      )
      useEffect(()=>{
        setGenPdfURL(data?.urlForS3Object ? data.urlForS3Object : '')
      },[data?.urlForS3Object, isFetched])
      const mutateSendTermsheet = useSendNewTermsheetMutation<Error>(
        graphqlRequestClient,
        {
          onSuccess: (
            data: SendNewTermsheetMutation,
            _variables: SendNewTermsheetMutationVariables,
            _context: unknown
          ) => {
            setLoading(false)
          },
          onError: (error: any) => {
            setLoading(false)
            Toast(error.response.errors[0].message, ToastType.error)
          },
        },
        {}
      )
      const mutateSignedDocument = useSendNewTermsheetMutation<Error>(
        graphqlRequestClient,
        {
          onSuccess: (
            data: SendNewTermsheetMutation,
            _variables: SendNewTermsheetMutationVariables,
            _context: unknown
          ) => {
            setLoading(false)
            queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
          },
          onError: (error: any) => {
            setLoading(false)
            Toast(error.response.errors[0].message, ToastType.error)
          },
        },
        {}
      )
    const handleGenSignPopupClose = (values:any)=>{
        setSignDialog(!signDialog);
        if(eSignId !== ""){
          const headers = {"Authorization": jwt !== undefined ? jwt : ""} 
          axios.get(`${esignApi}/admin/status?id=${eSignId}`, {headers}).then(async (data)=>{
            if(Object.keys(data?.data?.data?.signers).length>0){
              if(optionType === 'genPdf'){
                mutateSendTermsheet.mutate({
                    input:{
                      termsheetId:documentData?.id,
                      eSignId:eSignId,
                      uploadPdfURL:documentData?.fileURL,
                      status:'Pending Signature'
                    }
                })
              }else if(optionType === 'modifiedPdf'){
                mutateSendTermsheet.mutate({
                  input:{
                    termsheetId:documentData?.id,
                    eSignId:eSignId,
                    uploadPdfURL:uploadPdfUrl,
                    status:'Pending Signature'
                  }
                })
              }
            }
          }).catch((err)=>{
            setLoading(false)
            console.error(err);}
          )
        }
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
      }
      const handleContinue=()=>{
        const headers = { Authorization: jwt !== undefined ? jwt : '' }
          axios
            .get(`${esignApi}/admin/getAccessToken?esignId=${documentData.eSignId}`, { headers })
            .then(async (data) => {
              setIFrameToken(data.data.data.token)
              setSignDialog(true)
            })
            .catch((err) => {
              console.log(err)
            })
      }
      const handleChangeStatus = ({ meta }: any, status: any, xhr: any) => {
        if (meta.status === 'done') {
        }
      }
      const NewInstance = axios.create({
        // Configuration
        baseURL: '',
        timeout: 8000,
        headers: {},
      })
    const handleFinalSubmit=async ()=>{
        if(optionType === 'genPdf'){
            setLoading(true)
            const response = await fetch(genPdfURL)
            const blob = await response.blob()
            const file = new File([blob],'document.pdf')
            let base64 = await convertToBase64(file) as string
            if(base64.startsWith("data")){
                base64 = base64.split(";base64,")[1]
              }
              const sections = documentData?.sections || {}
              const investorNames = ['investorName','investorName2', 'investorName3', 'investorName4', 'investorName5'];
              const signerData:SignerData[] =[]
                let result:any ={}
                _.forEach(sections,(value, key) => {
                  result = {...result,...value}
                })
              investorNames.forEach(name => {
                if (result?.[name]) {
                  signerData.push({
                    name: result?.[name],
                    email: '',
                    designation: '',
                    signatureType: ''
                  });
                }
              });
              const founderCount = 5; // Number of founders
              
              for (let i = 1; i <= founderCount; i++) {
                const founderName = result[`founderName${i}`];
                const founderDesignation = result[`founderDesignation${i}`] || ""
              
                if (founderName) {
                  signerData.push({
                    name: founderName,
                    email: "",
                    designation: founderDesignation,
                    signatureType: ""
                  });
                }
              }
            const headers = {"Authorization":jwt !== undefined ? jwt : ""}
            axios.post(`${esignApi}/admin/uploadRequestDoc`,{file:base64,fileData:{fileName:documentData?.termsheetName?documentData.termsheetName:"New PDF",fileType:documentData?.type? documentData.type:"Document"},requestParams:{requestedBy:jwtDetails.emailId,reason:"Sign Please",responseUrl:`${invApi}/documentStatusUpdate`,companyName: documentData.companyName},signerData:signerData}, {headers}).then(
              (data)=>{
                setLoading(false)
                let token = data?.data?.token;
                if(!token){
                  token = data?.data?.data?.token;
                }
                setESignId(data?.data?.data?.esign_id)
                setIFrameToken(token)
                setSignDialog(!signDialog);
                mutateSendTermsheet.mutate({
                  input:{
                    termsheetId:documentData?.id,
                    eSignId:data?.data?.data?.esign_id,
                    uploadPdfURL:documentData?.fileURL,
                    status:'Pending Signature'
                  }
              })
              }
            ).catch(
              (err)=>{
                setLoading(false)
                console.error(err);
                Toast(err.response.data.message, ToastType.error)
              }
            );
        }else if(optionType === 'modifiedPdf'){
          setLoading(true)
          let base64String = await convertToBase64(uploadFile[0]) as string
          if(base64String.startsWith("data")){
            base64String = base64String.split(";base64,")[1]
          }
          const sections = documentData?.sections || {}
              const investorNames = ['investorName','investorName2', 'investorName3', 'investorName4', 'investorName5'];
              const signerData:SignerData[] =[]
                let result:any ={}
                _.forEach(sections,(value, key) => {
                  result = {...result,...value}
                })
              investorNames.forEach(name => {
                if (result?.[name]) {
                  signerData.push({
                    name: result?.[name],
                    email: '',
                    designation: '',
                    signatureType: ''
                  });
                }
              });
              const founderCount = 5; // Number of founders
              
              for (let i = 1; i <= founderCount; i++) {
                const founderName = result[`founderName${i}`];
                const founderDesignation = result[`founderDesignation${i}`]||""
              
                if (founderName) {
                  signerData.push({
                    name: founderName,
                    email: "",
                    designation: founderDesignation,
                    signatureType: ""
                  });
                }
              }
          const finalFile = await convertBase64ToBlob(base64String, 'application/pdf')
          const uploadNewFile = await NewInstance.put(uploadURL.data?.urlForS3Object || '',finalFile)
          const headers = {"Authorization":jwt !== undefined ? jwt : ""}
            axios.post(`${esignApi}/admin/uploadRequestDoc`,{file:base64String,fileData:{fileName:documentData?.termsheetName?documentData.termsheetName:"New PDF",fileType:documentData?.type? documentData.type:"Document"},requestParams:{requestedBy:jwtDetails.emailId,reason:"Sign Please",responseUrl:`${invApi}/documentStatusUpdate`,companyName: documentData.companyName},signerData:signerData}, {headers}).then(
              (data)=>{
                setLoading(false)
                let token = data?.data?.token;
                if(!token){
                  token = data?.data?.data?.token;
                }
                setESignId(data?.data?.data?.esign_id)
                setIFrameToken(token)
                setSignDialog(!signDialog);
                mutateSendTermsheet.mutate({
                  input:{
                    termsheetId:documentData?.id,
                    eSignId:data?.data?.data?.esign_id,
                    uploadPdfURL:uploadPdfUrl,
                    status:'Pending Signature'
                  }
                })
              }
            ).catch(
              (err)=>{
                setLoading(false)
                console.error(err);}
            );
        }else if(optionType === 'preSignPdf'){
          setLoading(true)
          let base64String = await convertToBase64(uploadFile[0]) as string
          if(base64String.startsWith("data")){
            base64String = base64String.split(";base64,")[1]
          }
          const finalFile = await convertBase64ToBlob(base64String, 'application/pdf')
          const uploadNewFile = await NewInstance.put(signedURL.data?.urlForS3Object || '',finalFile)
          mutateSignedDocument.mutate({
            input:{
              termsheetId:documentData?.id,
              eSignId:eSignId,
              uploadPdfURL:signedPdfUrl,
              signedPdfURL:signedPdfUrl,
              isSigned:true,
              status:'Active'
            }
          })
          handleClose()
        }
    }
    const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
      const { name, percent, status, size, validationError } = meta
      return (
        <div className='dropzone dropzone-queue p-9 pt-0'>
          <div className='dropzone-items'>
            <div className='dropzone-item p-2 dz-image-preview'>
              <div className='dropzone-file'>
                <div className='dropzone-filename' title={name}>
                  <span className='fs-base fw-bold'>{name}</span>{' '}
                  <strong>
                    (
                    <span>
                      {size > 1000000
                        ? (size / 1048576).toFixed(2) + ' MB'
                        : (size / 1024).toFixed(2) + ' KB'}
                    </span>
                    )
                  </strong>
                </div>
                <div className={`${status === 'done' ? 'text-success' : 'dropzone-error'}`}>
                  {status}
                </div>
              </div>
              <div className='dropzone-progress'>
                <div className='progress'>
                  <div
                    className={`${status === 'done' ? 'bg-success' : 'bg-primary'}`}
                    role='progressbar'
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={percent}
                    data-dz-uploadprogress
                    style={{ width: percent + '%' }}
                  ></div>
                </div>
              </div>
              <div className='dropzone-toolbar'>
              <button
          className='btn btn-active-light-primary btn-icon'
          onClick={() => {
            fileWithMeta.remove()
          }}
        >
        <KTSVG path="media/icons/duotune/general/gen040.svg" className="svg-icon-muted svg-icon-2hx" />
        </button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    const Input = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
      const [error,setError] = useState(false)
      const text = files.length > 0 ? 'Add more files' : 'Choose files'
      const file = files.length > 0 && files[0]
  
      const fileList = files.map((file) => (
        <h6 key={file.file.name}>
          {file.file.name} -{' '}
          {file.file.size > 1000000
            ? (file.file.size / 1000000).toFixed(2) + ' MB'
            : (file.file.size / 1000).toFixed(2) + ' KB'}
        </h6>
      ))
  
      return (
        <div className=''>
          <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-opacity-10 py-12 d-flex justify-content-center m-9 mb-2'>
            <input
              style={{ display: 'none' }}
              type='file'
              accept={accept}
              onChange={async (e) => {
                if(e?.target?.files![0].size > 15000000){
                  setError(true)
                  e.target.value=""
                }else{
                  setError(false)
                let file = await getFilesFromEvent(e)
                onFiles(file)
                setUploadFile(file)
                }
              }}
              multiple={false}
            />
            <div className='text-center'>
              <KTSVG
                path='/media/icons/duotune/general/gen041.svg'
                className={`svg-icon-3x me-5 text-gray-600 text-hover-primary`}
              />
              <h2 className='text-gray-600 my-2'>Drag & drop or browse your files</h2>
            </div>
          </label>
          <div className='ms-9 text-gray-500'>Supported formats: PDF</div>
          {error&&<div className="ms-9 text-danger">File size should be less than 15MB</div>}
        </div>
      )
    }
  
    const Layout = ({
      input,
      previews,
      submitButton,
      dropzoneProps,
      files,
      extra: { maxFiles },
    }: ILayoutProps) => {
      return (
        <div {...dropzoneProps} className=''>
          <div className=''>{input}</div>
  
          {previews}
  
          {/* {files.length > 0 && submitButton} */}
        </div>
      )
    }
    if(isLoading){
      return(
        <div className='d-flex justify-content-center align-items-center'>
      <span className='spinner-grow'></span>
    </div>
      )
    }
    if(!showWarnDialog && !signDraftStatus){
      return(
        <>
            <div className='d-flex justify-content-between'>
                <div>
            <h2>Document Signature</h2>
            <h5 className='text-gray-400 fw-bold'>Choose the option before sending the document for signature</h5>
            </div>
            </div>
            <a href={genPdfURL} target='_blank' rel='noopener noreferrer' className='text-decoration-underline text-popover py-3 fs-5'>{documentData.termsheetName}</a>
                <label className='d-flex p-2' onClick={()=>{setOptionType('genPdf')}}>
                <input className='form-check-input mt-1' type='radio' name='signRadio' value={"genPdf"} checked={optionType === 'genPdf'} onClick={(e:any)=>{
                    setOptionType(e.target?.value)
                }}/>
                  <h6 className=' ms-2 my-2'>Send the above Document for signing without any modifications</h6>
                  </label>
                <label className='d-flex p-2' onClick={(e:any)=>{setOptionType('modifiedPdf')}}>
                <input className='form-check-input mt-1' type='radio' name='signRadio' value={"modifiedPdf"} onClick={(e:any)=>{
                    setOptionType(e.target?.value)
                }}/>
                 <h6 className='ms-2 my-2'> Upload a modified version of the above document and send it for signing</h6>
                  </label>
                <label className='d-flex p-2' onClick={(e:any)=>{setOptionType('preSignPdf')}}>
                <input className='form-check-input mt-1' type='radio' name='signRadio' value={"preSignPdf"} onClick={(e:any)=>{
                    setOptionType(e.target?.value)
                }}/>
                  <h6 className=' ms-2 my-2'>Upload a pre-signed copy of the above document</h6>
                  </label>
            {optionType !== 'genPdf' && <div>
              <Dropzone
              onChangeStatus={handleChangeStatus}
              autoUpload={true}
              styles={{ dropzone: { minHeight: 200 } }}
              accept={
                'application/pdf'
              }
              multiple={false}
              maxFiles={1}
              PreviewComponent={Preview}
              maxSizeBytes={100000000}
              SubmitButtonComponent={() => <></>}
              InputComponent={Input}
              LayoutComponent={Layout}
            />
            </div>}
            <div>
            <button
              className='btn btn-secondary mt-5'
              disabled={loading || showWarnDialog}
              onClick={() => {
                setShowWarnDialog(!showWarnDialog)
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary mt-5 float-end'
              onClick={()=>{
                handleFinalSubmit()
              }}
              disabled={loading || showWarnDialog}
            >
              {!loading ? optionType === 'preSignPdf'? 'Upload': "Send for Signing"
              :(
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                  Loading...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
            </button>
          </div>
          {signDialog&&
          <SignatureIframe showDialog = {signDialog} token = {iFrameToken} handleClose = {handleGenSignPopupClose}/>}
          </>
      )
    }
    if(showWarnDialog && !signDraftStatus){
      return(
        <>
            <div className="d-flex justify-content-center">
            <h1 className='text-warning mt-2'>Warning <KTSVG path="media/icons/duotune/general/gen044.svg" className="svg-icon-warning svg-icon-2hx" /></h1>
            </div>
            <div className="text-center"><h3>You are about to Cancel and all uploaded files will be lost.</h3>
            <h3>Are you sure you want to procees ?</h3></div>
            <div>
                <button
                  className='btn btn-secondary mt-5'
                  onClick={() => {
                    setShowWarnDialog(!showWarnDialog)
                  }}
                >
                  Back
                </button>
                <button
                  className='btn btn-primary mt-5 float-end'
                  onClick={()=>{
                    setLoading(true)
                    handleClose()
                  }}
                  disabled={loading}
                >
                  Confirm
                </button>
              </div>
            </>
      )
    }
    if(signDraftStatus){
      return(
        <React.Fragment>
            <>
    <div>
      <h3>You have an outstanding signature process pending. Would you like to continue with the current process or request a new signature?</h3>
    </div>
    <div>
          <button
            className='btn btn-sm btn-secondary mt-5'
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-sm btn-primary mt-5 float-end'
            onClick={() => {
              handleContinue()
            }}
          >
            Continue Process
          </button>
          <button
            className='btn btn-sm btn-primary mt-5 me-2 float-end'
            onClick={() => {
              setShowWarnDialog(false)
              setSignDraftStatus(false)
            }}
          >
            Request New Signature
          </button>
        </div>
    </>
    {signDialog&&(
                        <SignatureIframe showDialog = {signDialog} token = {iFrameToken} handleClose = {()=>{setSignDialog(!signDialog) 
                          handleClose()
                        }}/>
                      )}
    </React.Fragment>
      )
    }
    return <></>
}
export function RevokeSign({documentData,handleClose}:Props){
  const jwt = getAuth()
  const esignApi = process.env.REACT_APP_ESIGN_API_URL
  const [isLoading,setIsLoading] = useState(false)
  const [revokeReason,setRevokeReason]= useState('')
  const mutateSendTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const handleRevokeSign= ()=>{
    setIsLoading(true)
    const headers = {"Authorization": jwt !== undefined ? jwt : ""}
    const body = {
      "esignId":documentData.eSignId,
      "reason":revokeReason
    }
    axios.post(`${esignApi}/admin/cancelRequest`,body, {headers})
    .then((data)=>{
      Toast('Sign Request Revoked Successfully!', ToastType.success)
      mutateSendTermsheet.mutate({
        input:{
          termsheetId:documentData?.id,
          eSignId:"",
          status:'Draft',
          uploadPdfURL:"",
          isSignRevoked:true
        }
      })
      setIsLoading(false)
      handleClose()
    })
    .catch((err)=>{
      console.log(err)
      setIsLoading(false)
    })
  }
    return(
        <React.Fragment>
            <h2 className="text-center py-5">Are you sure you want to cancel?</h2>
            <div>
              <h2>Reason :</h2>
              <textarea className="w-100" onChange={(e:any)=>{
                setRevokeReason(e.target.value)
              }}/>
            </div>
            <div>
            <button
              className='btn btn-secondary'
              onClick={()=>{
                handleClose()
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-primary float-end'
              disabled={isLoading}
              onClick={()=>{
                handleRevokeSign()
              }}
            >
                Revoke
            </button>
          </div>
        </React.Fragment>
    )
}
export function ResumeSignProcess({documentData }: Props){
    const jwt = getAuth()
    const esignApi = process.env.REACT_APP_ESIGN_API_URL
    const [iFrameToken,setIFrameToken] = useState('')
    const [signDialog,setSignDialog] = useState<boolean>(false)
    const handleContinue=()=>{
      const headers = { Authorization: jwt !== undefined ? jwt : '' }
        axios
          .get(`${esignApi}/admin/getAccessToken?esignId=${documentData.eSignId}`, { headers })
          .then(async (data) => {
            setIFrameToken(data.data.data.token)
            setSignDialog(true)
          })
          .catch((err) => {
            console.log(err)
          })
    }
  return(
    <React.Fragment>
                <>
        <div>
          <h3>You have an outstanding signature process pending. Would you like to continue with the current process or request a new signature?</h3>
        </div>
        <div>
              <button
                className='btn btn-sm btn-secondary mt-5'
                onClick={() => {
                }}
              >
                Cancel
              </button>
              <button
                className='btn btn-sm btn-primary mt-5 float-end'
                onClick={() => {
                  handleContinue()
                }}
              >
                Continue Process
              </button>
              <button
                className='btn btn-sm btn-primary mt-5 me-2 float-end'
                onClick={() => {
                }}
              >
                Request New Signature
              </button>
            </div>
        </>
    {signDialog&&(
                        <SignatureIframe showDialog = {signDialog} token = {iFrameToken} handleClose = {()=>{setSignDialog(!signDialog)}}/>
                      )}
    </React.Fragment>
  )
  }