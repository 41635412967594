import { FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import AuthVerify from '../modules/auth/core/AuthVerify'
import { useAuth } from '../modules/auth'
import { SendTermsheetWizard } from '../pages/open-deals/components/termsheet-issuer/SendTermsheetWizard'
import TermsheetView from '../pages/open-deals/components/TermsheetView'
import DraftTermsheetView from '../pages/open-deals/components/termsheet-issuer/DraftTermsheetView'
import IssueTermSheetWrapper from '../pages/open-deals/components/termsheet-issuer/IssueTermsheetWrapper'
import AccountsWrapper from '../pages/accounts/AccountsWrapper'
import AddInvestor from '../pages/accounts/components/AddInvestor'
import AddAccount from '../pages/accounts/components/AddAccount'
import EEWrapper from '../pages/exitEnabler/EEWrapper'
import { FundMetricsWrapper } from '../pages/FundMetrics/FundMetricsWrapper'
import { useCanUserAccess } from '../pages/store/userRoleGrants'
import { NoAccess } from '../pages/accounts/components/NoAccess'
import PortfolioPageWrapper from '../pages/portfolio/PortfolioPageWrapper'
import CompanyProfileRouter from '../pages/company/wrappers/CompanyProfileRouter'
import WatchlistPageWrapper from '../pages/watchlist/WatchlistPageWrapper'
import DashboardPageWrapper from '../pages/dashboard/DashboardPageWrapper'
import DealsPageWrapper from '../pages/deals/wrappers/DealsPageWrapper'
import TemplatePageWrapper from '../pages/deals/wrappers/TemplatePageWrapper'
import { CompanyDataInput, CompanyTypes } from '../../generated/graphql'
import { useChatbotStore } from '../../store/termsheetChatbot'
import ReportsPageWrapper from '../pages/reports/ReportsPageWrapper'
import DocumentIssuePageWrapper from '../pages/deals/wrappers/DocumentIssuePageWrapper'
import DocumentIssueDraftPageWrapper from '../pages/deals/wrappers/DocumentIssueDraftPageWrapper'
import MyCaptablesPageWrapper from '../pages/my-captables/MyCaptablesPageWrapper'
import DynamicTablePageWrapper from '../pages/dynamic-tables/wrappers/DynamicTablesPageWrapper'
import DynamicTableRouter from '../pages/dynamic-tables/wrappers/DynamicTableRouter'
import { Error404 } from '../modules/errors/components/Error404'
import UserManagementWrapper from '../pages/accounts/UserManagementWrapper'
import TaskManagerWrapper from '../pages/taskManager/TaskManagerWrapper'
import DealFlowWrapper from '../pages/taskManager/DealFlowWrapper'

const PrivateRoutes = () => {
  const location = useLocation()
  const { logout, isDefaultAccount } = useAuth()

  const canDashboard = useCanUserAccess('read', 'dashboard')
  const canPortfolio = useCanUserAccess('read', 'portfolio')
  const canWatchlist = useCanUserAccess('read', 'watchlist')
  const canDeals = useCanUserAccess('read', 'documentAssembler')
  const canFundMetrics = useCanUserAccess('read', 'fundMetrics')
  const canCompanyAccess = useCanUserAccess('read', 'companyAccess')
  const canExitEnabler = useCanUserAccess('read', 'exitEnabler')
  const canExitEnablerAdmin = useCanUserAccess('read', 'exitEnablerAdmin')
  const canPrivateCaptables = useCanUserAccess('read', 'myCaptables')
  const canDynamicTables = useCanUserAccess('read', 'dynamicTable')
  const isAdmin = useCanUserAccess('*', '*')
  const canProject = useCanUserAccess('read', 'project')
  const canDealFlow = useCanUserAccess('read', 'dealFlow')

  // const availableAccess = canDashboard
  //   ? '/dashboard'
  //   : canPortfolio
  //   ? '/portfolio'
  //   : canWatchlist
  //   ? '/watchlist'
  //   : canDeals
  //   ? '/deals'
  //   : canFundMetrics
  //   ? '/fundMetrics'
  //   : '/noAccess'

  let companyId = null
  let companyType = CompanyTypes.Hissa
  if (canCompanyAccess) {
    const userCompanies =
      (JSON.parse(localStorage.getItem('userCompanies')!) as CompanyDataInput[]) || []
    if (userCompanies.length > 0) {
      companyId = userCompanies[0].hissaCompanyId
      companyType = userCompanies[0].type
    }
  }

  const availableAccess = canCompanyAccess
    ? `/company/summary/${companyType}/${companyId}`
    : canDashboard
    ? '/dashboard'
    : canPortfolio
    ? '/portfolio'
    : canDeals
    ? '/contracts'
    : canFundMetrics
    ? '/fundMetrics'
    : canWatchlist
    ? '/watchlist'
    : canExitEnabler
    ? '/exitEnabler'
    : canExitEnablerAdmin
    ? '/exitEnabler'
    : canPrivateCaptables
    ? '/myCaptables'
    : canDynamicTables
    ? '/privateData'
    : canProject
    ? '/taskManager'
    : canDealFlow
    ? '/dealFlow'
    : '/noAccess'

  const userChatBotStore = useChatbotStore()
  useEffect(() => {
    if (location && location.pathname && !location.pathname.endsWith('sendtermsheet')) {
      userChatBotStore.setIsClear(true)
    }
  }, [location])

  return (
    <div className='h-100'>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='auth/*' element={<Navigate to={availableAccess} />} />
          <Route path='/*' element={<Navigate to={availableAccess} />} />
          {/* Pages */}
          {canPortfolio && <Route path='portfolio' element={<PortfolioPageWrapper />} />}
          {canWatchlist && <Route path='watchlist' element={<WatchlistPageWrapper />} />}
          {canDashboard && <Route path='dashboard' element={<DashboardPageWrapper />} />}
          {canDeals && <Route path='contracts' element={<DealsPageWrapper />} />}
          {canDeals && <Route path='contracts/sendtermsheet' element={<DocumentIssuePageWrapper />} />}
          {canDeals && <Route path='contracts/termsheetview' element={<TermsheetView />} />}
          {canDeals && (
            <Route path='contracts/draftTermsheet' element={<DocumentIssueDraftPageWrapper />} />
          )}
          {canDeals && <Route path='contracts/templateViewer' element={<TemplatePageWrapper />} />}
          {canDeals && <Route path='contracts/issueTermsheet' element={<IssueTermSheetWrapper />} />}
          {canFundMetrics && <Route path='fundMetrics' element={<FundMetricsWrapper />} />}
          {/* {canPrivateCaptables && <Route path='myCaptables' element={<MyCaptablesPageWrapper />} />} */}
          {canProject && <Route path='taskManager' element={<TaskManagerWrapper />} />}
          {canDealFlow && <Route path='dealFlow' element={<DealFlowWrapper />} />}
          {/* Lazy Modules */}
          <Route
            path='company/*'
            element={
              <SuspensedView>
                <CompanyProfileRouter />
              </SuspensedView>
            }
          />{' '}
          {canDynamicTables && (
            <Route
              path='privateData/*'
              element={
                <SuspensedView>
                  <DynamicTableRouter />
                </SuspensedView>
              }
            />
          )}{' '}
          <Route
            path='exitEnabler/*'
            element={
              <SuspensedView>
                <EEWrapper />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
          <Route path='noAccess' element={<NoAccess />} />
          <Route path='404' element={<Error404 />} />
          {/* Add user to account*/}
          {isAdmin && <Route path='userManagement' element={<UserManagementWrapper />} />}
          {isAdmin && <Route path='adduser' element={<AccountsWrapper />} />}
          {isAdmin && <Route path='addInvestor' element={<AddInvestor />} />}
          {isAdmin && <Route path='addAccount' element={<AddAccount />} />}
        </Route>
      </Routes>
      <AuthVerify logout={logout} />
    </div>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
