import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  AllTransactionsType,
  CompanyObject,
  TransactionObject,
  Transaction_Type,
} from '../../../../generated/graphql'
import {
  TransactionActionRender,
  AmountInvestedRender,
  DateOfInvestmentRender,
  InvestorNameRender,
  NoOfSharesRender,
  RoundNameRender,
  SecuritiesRender,
  ShareDistinctiveRender,
  TagsRender,
  TransactionTypeRender,
} from './CompanyTransactionAGComponents'
import { KTSVG } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'

type TransactionProps = {
  data: AllTransactionsType
}

export function CompanyTransactionAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  let transactionData: TransactionObject[] = props.data.transactionData as TransactionObject[]
  let companyDetails: CompanyObject = props.data.companyData as CompanyObject
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const componentsRegistry = useMemo(
    () => ({
      investorName: memo(InvestorNameRender),
      transactionType: memo(TransactionTypeRender),
      roundName: memo(RoundNameRender),
      transactionAmount: memo(AmountInvestedRender),
      security: memo(SecuritiesRender),
      dateOfInvestment: memo(DateOfInvestmentRender),
      // shareDistinctiveNumbers: memo(ShareDistinctiveRender),
      noOfShares: memo(NoOfSharesRender),
      tags: memo(TagsRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <p className='fs-base text-muted text-center'>No Transactions found in this company.</p>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [dataToggle, setDataToggle] = useState('investments')
  // const [showModal, setShowModal] = useState<boolean>(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Fund / Investor',
        field: 'investorName',
        cellRenderer: 'investorName',
        cellStyle: tableCellStyle,
        // pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Transaction Type',
        field: 'transactionType',
        filter: 'agTextColumnFilter',
        cellRenderer: 'transactionType',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Round Name',
        field: 'roundName',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'roundName',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: `Transaction Amount (${getSymbolOfCurrency(userCurrency)})`,
        field: 'transactionAmount',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'transactionAmount',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
      {
        headerName: 'Security',
        field: 'security',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'security',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipUnrealizedValue',
      },
      {
        headerName: 'Transaction Date',
        field: 'dateOfInvestment',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0
            }
            const dateParts = cellValue.split('-')
            const day = Number(dateParts[2]?.split('T')[0])
            const month = Number(dateParts[1]) - 1
            const year = Number(dateParts[0])
            const cellDate = new Date(year, month, day)
            if (cellDate < dateFromFilter) {
              return -1
            } else if (cellDate > dateFromFilter) {
              return 1
            }
            return 0
          },
        },
        cellRenderer: 'dateOfInvestment',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },

      {
        headerName: 'Number Of Securities',
        field: 'noOfShares',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'noOfShares',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        hide: true,
        tooltipField: 'tooltipNoOfShares',
      },
      // {
      //   headerName: 'Share Distinctive Numbers',
      //   field: 'shareDistinctiveNumbers',
      //   filter: 'agNumberColumnFilter',
      //   cellRenderer: 'shareDistinctiveNumbers',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   menuTabs: ['filterMenuTab'],
      //   hide: true,
      //   tooltipField: 'tooltipFdbShares',
      // },
      {
        headerName: 'Tags',
        field: 'tags',
        filter: 'agTextColumnFilter',
        cellRenderer: 'tags',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: TransactionActionRender,
      },
    ],
    [userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      transactionData?.map((transaction: TransactionObject) => {
        const roundName =
          transaction.transactionType === Transaction_Type.Buy
            ? transaction.round
            : // : transaction.transactionType === Transaction_Type.SecondaryBuy
              // ? 'Secondary'
              ''
        const transactionType =
          transaction.transactionType === Transaction_Type.Sell
            ? 'SECONDARY_SELL'
            : transaction.transactionType
        return {
          investorName: transaction.investorsData?.name || transaction.investorName,
          transactionType: transactionType.split('_').join(' '),
          roundName: roundName,
          transactionAmount: Math.abs(transaction.amount),
          tooltipAmountInvested: Math.abs(transaction.amount).toLocaleString(userCurrencyFormat),
          security: transaction.securitySubType,
          dateOfInvestment: transaction.transactionDate,
          noOfShares: transaction.noOfShares,
          tooltipNoOfShares: Math.abs(transaction.noOfShares).toLocaleString(userCurrencyFormat),
          // shareDistinctiveNumbers: '-',
          tags: transaction.tags,
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
          transactionDetails: transaction,
          companyDetails: companyDetails,
          notes: transaction.notes,
          isIndirect:
            props.data.indirectTransactionData && props.data.indirectTransactionData.length > 0
              ? true
              : false,
          isLastInvestment: transactionData.length === 1 ? true : false,
        }
      }),
    [transactionData, userCurrencyFormat]
  )

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode
    const filteredData = data.childrenAfterAggFilter.map((element: any) => element.data)
    const totalInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.transactionAmount,
      0
    )

    const noOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.noOfShares,
      0
    )
    api.setPinnedBottomRowData([
      {
        investorName: 'Total',
        transactionType: null,
        roundName: null,
        transactionAmount: Math.abs(totalInvested),
        tooltipAmountInvested: Math.abs(totalInvested).toLocaleString(userCurrencyFormat),
        security: null,
        dateOfInvestment: null,
        noOfShares: noOfShares,
        tooltipNoOfShares: Math.abs(noOfShares).toLocaleString(userCurrencyFormat),
        tags: null,
        userCurrencyFormat: userCurrencyFormat,
        userCurrency: userCurrency,
        transactionDetails: null,
        companyDetails: companyDetails,
        isIndirect: null,
        isLastInvestment: null,
      },
    ])
  }

  return (
    <div
      className='card p-0 h-600px overflow-auto'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>My Transactions</h1>
          <span className='text-muted'>
            {transactionData.length > 0 ? `${transactionData.length} Transactions` : ''}
          </span>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onRowDataUpdated={setPinnedBottomRowData}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
