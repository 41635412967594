import { lazy, Suspense } from 'react'

const PdfDocument = lazy(() => import('./PdfDocument'))

function LazyPdfDocument({ url }: { url: string }) {
  return (
    <Suspense fallback={<div className='col-12 h-550px d-flex justify-content-center'>
      <div className='spinner-grow align-self-center' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>}>
      <PdfDocument url={url} />
    </Suspense>
  )
}

export default LazyPdfDocument
