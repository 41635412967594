import { useRef, useState } from 'react'
import { numToWords } from './NumToWords'
import { getCurrency } from './CurrencyFormatter'

type NumberInputBoxType = {
  isDisabled: boolean
  name: string
  handleChange: Function
  setFieldValue: Function
  currency?: string
  placeholder?: string
  value: string | number
  isCurrency?: boolean
  type: 'number' | 'text'
  autocomplete?: boolean
}
export const InputBox = (props: NumberInputBoxType) => {
  const currencyFormat = getCurrency(props.currency || 'INR - ₹')
  const currency = props.currency?.split('-')[0].trim()

  const [value, setValue] = useState<string>(props.value.toString() || '')
  const [moneyInWords, setMoneyInWords] = useState<string>()
  numToWords(Number(props.value), currency || 'INR')
  const [isInputFocused, setIsInputFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const formatNumberWithCommas = (value: string) => {
    const [integer, decimal] = value.split('.')
    const formattedInteger = Intl.NumberFormat(currencyFormat).format(parseInt(integer || '0', 10))
    return decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target
    const selectionStart = input.selectionStart || 0
    const oldValue = input.value

    let rawValue = oldValue.replaceAll(',', '')
    if (props.type === 'number') {
      if (/^[0-9]*\.?[0-9]*$/.test(rawValue)) {
        setValue(rawValue)

        const formattedValue = formatNumberWithCommas(rawValue)
        const cursorAdjustment = formattedValue.length - oldValue.length

        props.setFieldValue(parseFloat(rawValue) || 0)
        setMoneyInWords(
          numToWords(parseFloat(rawValue) || 0, currency || 'INR', props.isCurrency || false)
        )

        setTimeout(() => {
          if (inputRef.current) {
            const newCursorPosition = selectionStart + cursorAdjustment
            inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition)
          }
        }, 0)
      }
    } else if (props.type === 'text') {
      setValue(rawValue)
      props.setFieldValue(rawValue)
      setMoneyInWords('')
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        type='text'
        autoComplete={props.autocomplete ? 'on' : 'off'}
        className='form-control custom-input fw-bold shadow-lg h-38px'
        name={props.name}
        placeholder={props.placeholder}
        disabled={props.isDisabled}
        onChange={handleChange}
        value={props.type === 'number' ? formatNumberWithCommas(value) : value}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') e.preventDefault()
        }}
        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
        onFocus={(e: any) => {
          setIsInputFocused(true)
          if (props.type === 'number') {
            let rawValue = e?.target?.value?.replaceAll(',', '')
            setMoneyInWords(
              numToWords(
                parseFloat(rawValue || 0) || 0,
                currency || 'INR',
                props.isCurrency || false
              )
            )
          }
        }}
        onBlur={() => setIsInputFocused(false)}
      />
      {isInputFocused && props.type === 'number' && (
        <div className='fs-9 text-gray-600'>{moneyInWords}</div>
      )}
    </>
  )
}

export const TextInputBox = (props: NumberInputBoxType) => {}
