// import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';
import { PackageCreationComponent } from "@rulezerotech/doc-builder";
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../_metronic/helpers';
import esignClient from "../../../../api/esignClientApi";
import {
  CompanyTypes,
  GetCompanyWiseDataQuery,
  GetGlobalCompaniesQuery,
  GetInvestorsQuery,
  GetS3UrlForTermsheetQuery,
  GetUrlForS3ObjectQuery,
  GlobalCompanySearchType,
  Investor,
  SendNewTermsheetMutation,
  SendNewTermsheetMutationVariables,
  TermsheetInput,
  useGetCompanyWiseDataQuery,
  useGetDealTemplatesQuery,
  useGetGlobalCompaniesQuery,
  useGetInvestorsQuery,
  useGetS3UrlForTermsheetQuery,
  useGetTermsheetsQuery,
  useGetUrlForS3ObjectQuery,
  useSendNewTermsheetMutation,
} from '../../../../generated/graphql';
import { graphqlRequestClient, queryClient } from '../../../../queries/client';
import { useChatbotStore } from '../../../../store/termsheetChatbot';
import { convertBase64ToBlob } from '../../../../utils/ExportExcel';
import { getNameForKey } from '../../../../utils/TermsheetUtils';
import Toast, { ToastType } from '../../../../utils/toast';
import { getAuth, JwtPayload } from '../../../modules/auth';
import EmailTermsheet from '../../open-deals/components/emailTermsheet/EmailTermsheet';
import { ImportInvestmentModel } from '../../open-deals/components/termsheet-issuer/ImportInvestmentModel';
import SignatureIframe from '../components/signature-flow/SignatureIframe';

type Props = {
  allowedSections?: string[]
  wizardRef?: any
  templateId?: number
  initialValues?: any
  draftTermsheetId?: number
  genericData?: any
}
type MapSchemaTypes = {
  string: string
  boolean: boolean
  number: number
  stringArray: string[]
  objectArray: any[]
}
export type SignerData = {
  name: string
  designation: string
  email: string
  signatureType: string
}
function DocumentIssuePageWrapper({
  allowedSections,
  templateId,
  initialValues,
  draftTermsheetId,
  genericData,
}: Props) {
  const jwt = getAuth()
  const jwtDetails = jwtDecode<JwtPayload>(jwt!)
  const esignApi = process.env.REACT_APP_ESIGN_API_URL
  const invApi = process.env.REACT_APP_API_URL
  const location = useLocation()
  const useChatBot = useChatbotStore()
  const navigate = useNavigate()
  const [aiInits, setAiInits] = useState<any>()
  const [isStepperCollapsed, setIsStepperCollpased] = useState(false)
  const [termsheetId, setTermsheetId] = useState<any>()
  const stepper = useRef<StepperComponent | null>(null)
  const [formikValues, setFormikValues] = useState<any>('')
  const [showShareEmailModal, setShowShareEmailModal] = useState(false)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const submitBtnRef = useRef<HTMLButtonElement | null>(null)
  const initialStepperErrors: StepperErrors = {}
  const [stepperErrors, setStepperErrors] = useState<StepperErrors>(initialStepperErrors)
  const [fieldValue, setFieldValue] = useState<any>('')
  const [recFields, setRecommendedFields] = useState<any>([])
  const [docType, setDocType] = useState('')
  const [loading, setLoading] = useState(false)
  const [hissaCompanyId, setHissaCompanyId] = useState<string>('')
  const [newPdf, setNewPdf] = useState<any>(' ')
  const [queryEnabled, setQueryEnabled] = useState<boolean>(false)
  const [companyObj, setCompanyObj] = useState<any>()
  const [showSign, setShowSign] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<any>("")
  const [blobPdfUrl, setBlobPdfUrl] = useState('');
  const [sfdt, setSfdt] = useState<any>(
    'UEsDBBQAAAAIAJtRQ1fyWXzmlgEAAOsDAAAEAAAAc2ZkdMRSy07DMBD8FbRcoypuSwu5IVBPqELiCBzsxE4s5SXHFdAq/86sHSKoxBHhQzK7tndnZn2irve2sUf9ZApPmXcHndCgc8qeT6TCNzeUncgMR8qEWKQJGSToTtZWOUsIcZgu02lxwij784SRZ7HRZ4n2LJMPPWXpIh0T6kP/2rYhkZCbkZmRGiYgAURANaOFWN/w2m7E1bVYLzkNmdj2oMTVbdDoa7Sjh04Wti0XcTGLf5U+vo6vCVXMYQxD6R3TqQrKVpvAZkb9G2UbsQywomx7E2DdAAbkZuRnpGZUGO6If6cjYBF8q2d/UXKIf92CCzwE5bhvv/46nitbmE63TiqbQ4lie/b67VGWmiAHmZIVBNd20yLW5j/iGEIZNKN95xpZo0b7/j2KA7HQLdLVahyTcEfEO/fayEPtLx6lk6WTfXWx61pP3LfwMGgyrZkeAFgw5xgFaWxB5Zs6IOkd7A4z55fEqrk3MIh+fzUvB8x+hTbcKP9lf/21jyLTPmJI139SNe8aHMKo4KxXECTGTwAAAP//AwBQSwECLQAUAAAACACbUUNX8ll85pYBAADrAwAABAAAAAAAAAAAACAAAAAAAAAAc2ZkdFBLBQYAAAAAAQABADIAAAC4AQAAAAA='
  )
  const [isEdit, setIsEdit] = useState(false)
  const [templateName, setTemplateName] = useState<string>('My Document')
  const [currentStepIndex, setCurrentStepIndex] = useState<number | undefined>()
  const [showFreeTextModal, setShowFreeTextModal] = useState<boolean>(useChatBot.isOpen || false)
  const [signDialog, setSignDialog] = useState<boolean>(false)
  const [eSignId, setESignId] = useState<string>('')
  const [iFrameToken, setIFrameToken] = useState<string>('')
  const [companyName, setCompanyName] = useState('')
  const [isImportModel, setIsImportModel] = useState(false)
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const initialObj: any = {}
  const errorObj: any = {}

  type MapSchema<T extends Record<string, keyof MapSchemaTypes>> = {
    -readonly [K in keyof T]: MapSchemaTypes[T[K]]
  }
  function asSchema<T extends Record<string, keyof MapSchemaTypes>>(template: T): T {
    return template
  }
  function isNumeric(n: any) {
    return !isNaN(n)
  }
  const personSchema = asSchema(initialObj)
  const errorSchema = asSchema(errorObj)
  type TermsheetType = MapSchema<typeof personSchema>
  type StepperErrors = MapSchema<typeof errorSchema>
  const templateData = useMemo(() => genericData || (location.state as any)?.genericData, [
    genericData,
    location.state,
  ])
  const templateDetails = JSON.parse(templateData.jsonData)[0].templateDetails
  const filledValue: string[] = []
  if (aiInits !== undefined) {
    Object.keys(aiInits).map((obj, index) => {
      if (aiInits[obj] !== '' && aiInits[obj] != 0) {
        filledValue.push(obj)
      }
    })
  }
  const jsonData = JSON.parse(templateData.jsonData)
  useEffect(() => {
    setDocType(jsonData[0].type)
  }, [jsonData])
  useEffect(() => {
    const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])
  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])
  useEffect(() => {
    setTimeout(() => {
      !initialValues && setShowFreeTextModal(true)
    }, 1000)
  }, [])
  
  useEffect(() => {
    setCurrentStepIndex(stepper.current?.currentStepIndex)
  }, [stepper.current?.currentStepIndex])
  useEffect(() => {
    setShowFreeTextModal(useChatBot.isOpen)
  }, [useChatBot.isOpen])
  const reloadStepper = () => {
    if (StepperComponent.hasInstace(stepperRef.current as HTMLDivElement))
      StepperComponent.destroy(stepperRef.current as HTMLDivElement)
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  useEffect(() => {
    reloadStepper()
  }, [allowedSections])
  useEffect(() => {
    if (draftTermsheetId) setTermsheetId(draftTermsheetId)
  }, [draftTermsheetId])
  useEffect(() => {
    reloadStepper()
  }, [aiInits])
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef, jsonData])
  // const handleFreeTextModalClose = () => {
  //   setShowFreeTextModal(false)
  //   useChatBot.setIsOpen(false)
  // }
  const NewInstance = axios.create({
    // Configuration
    baseURL: '',
    timeout: 8000,
    headers: {},
  })
  // file type conversion
  // const convertEditedDocxToPdf = async () => {
  //   const docxBlob = await container.current?.documentEditor
  //     .saveAsBlob('Docx')
  //     .then((blob: Blob) => {
  //       return blob
  //     })

  //   if (docxBlob) {
  //     const uploadFile = await NewInstance.put(
  //       getTermsheetUrl.data?.getTermsheetUrlPath?.presignedDocUrl || '',
  //       docxBlob
  //     )
  //     const arrayBuffer = await docxBlob.arrayBuffer()
  //     const file = Buffer.from(arrayBuffer)
  //     const pdfB64 = await axios
  //       .post(`${process.env.REACT_APP_API_URL}/docToPdf`, { file: file }, {})
  //       .then((res) => {
  //         return res.data.base64
  //       })
  //       .catch((err) => {
  //         return err
  //       })
  //     const pdfBlob = await convertBase64ToBlob(pdfB64, 'application/pdf')
  //     if (pdfBlob) {
  //       const uploadFile = await NewInstance.put(
  //         getTermsheetUrl.data?.getTermsheetUrlPath?.presignedUrl || '',
  //         pdfBlob
  //       )
  //       let base64 = pdfB64
  //       if (!base64.startsWith('data:application/pdf;base64,'))
  //         base64 = `data:application/pdf;base64,${base64}`
  //       setNewPdf(base64)
  //     }
  //   }
  // }
  const handleSendDraftTermsheet = (termsheet: TermsheetInput) => {
    mutateSendDraftTermsheet &&
      mutateSendDraftTermsheet?.mutate({ input: termsheet, sendImmediately: false })
  }
  // const handleSendTermsheet = (termsheet: any) => {
  //   submitBtnRef.current?.setAttribute('data-kt-indicator', 'on')
  //   mutateSendTermsheet.mutate({ input: termsheet, sendImmediately: true })
  // }
  const handleSignPopupClose = (values: any) => {
    setSignDialog(!signDialog)
    if (eSignId !== '') {
      esignClient.get(`/admin/status?id=${eSignId}`)
        .then(async (data) => {
          if (Object.keys(data?.data?.data?.signers).length > 0) {
            const values = findDynamicKeys(documentData);
            const data: Record<string, string> = Object.assign({}, ...values);
            const termsheetData = getTermsheetData(data, documentData);
            termsheetData.fileUrl = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
            termsheetData.uploadPdfURL = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
            termsheetData.docURL = getTermsheetUrl.data?.getTermsheetUrlPath?.docUrlPath || ''
            termsheetData.status = 'Pending Signature'
            termsheetData.eSignId = eSignId
            if (termsheetId) {
              termsheetData.termsheetId = termsheetId
            }
            handleSendDraftTermsheet(termsheetData)
          }
        })
        .catch((err) => {
          setLoading(false)
          console.error(err)
        })
    }
    queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
  }
  const { data } = useGetInvestorsQuery<GetInvestorsQuery, Error>(graphqlRequestClient, {}, {})
  const investorList = data?.investors.map((investor: Investor) => {
    return {
      investorId: investor.id,
      investorName: investor.name,
    }
  })

  const usePreviewTermsheetMutation = useMutation(
    ['previewTermsheet'],
    (object: any) => axios.post(`${process.env.REACT_APP_API_URL}/termsheetPreview`, object),
    {
      onSuccess: async (data) => {
        let base64File: string = data.data?.base64
        if (!base64File.startsWith('data:application/pdf;base64,'))
          base64File = `data:application/pdf;base64,${base64File}`
        setPreviewUrl(base64File)
        setPackageData((prevState) => ({ ...prevState, previewUrl: base64File }))
        const pdfBlob = await convertBase64ToBlob(data.data?.base64, 'application/pdf')
        if (pdfBlob) {
          if (pdfBlob) {
            const uploadFile = await NewInstance.put(
              getTermsheetUrl.data?.getTermsheetUrlPath?.presignedUrl || '',
              pdfBlob
            )
          }
        }
        // const blob = await convertBase64ToBlob(
        //   data.data?.wordFile,
        //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        // )
        // const url = window.URL.createObjectURL(blob);
        // const blobPdf = await convertBase64ToBlob(data.data?.base64, 'application/pdf')
        // setUpload(blob)
        // const urlPdf = URL.createObjectURL(blobPdf);
        // setBlobPdfUrl(url);
        // onFileChange(data.data?.base64)
        // onFileChange(data.data?.wordFile)
        setLoading(false)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setLoading(false)
      },
    }
  )
  const mutateSendDraftTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        // setLoading(false)
        setQueryEnabled(true);
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.termsheetId!)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const getTermsheetUrl = useGetS3UrlForTermsheetQuery<GetS3UrlForTermsheetQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        companyId: hissaCompanyId,
        accountId: Number(templateData.accountId),
      },
    },
    {
      enabled: queryEnabled,
      onSuccess: () => {
        setQueryEnabled(false)
      },
    }
  )
  const mutateSendTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        setTermsheetId(Number(data.addTermsheet.saved))
        submitBtnRef.current?.removeAttribute('data-kt-indicator')
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.id!)
        Toast('Document Saved Successfully!', ToastType.success)
        navigate('/deals')
      },
      onError: (error: any) => {
        setLoading(false)
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        key: templateData?.thumbnailURL,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
      },
    },
    {
      enabled:
        templateData?.thumbnailURL !== undefined &&
        templateData?.thumbnailURL !== null &&
        templateData?.thumbnailURL !== '',
    }
  )
  function convertToNumber(input: string | number) {
    let converted = Number(input);
    return isNaN(converted) ? NaN : converted;
  }
  //creating the initial values for the formik form
  const getTermsheetData = (values: Record<string, string | number>, item: any) => {
    const schemaObj = ["investorId", "templateId", "sections", "termsheetName", "companyName", "cin", "roundSize", "valuation", "expiryDate", "investmentAmount", "type", "esignId"];
    let termsheetData: Record<string, string | number | any> = {}; // hashmap
    for (let key of schemaObj) {

      termsheetData[key] = convertToNumber(values[key]) ? Number(values[key]) : values[key];
      if (key === 'sections') {
        termsheetData['sections'] = {}
      }
    }

    jsonData.forEach((section: any, sectionIndex: number) => { //jsonData
      const stepKey = `step${section.sequenceNumber}`
      if (!termsheetData['sections'][`${section.stepname}`]) {
        termsheetData['sections'][`${section.stepname}`] = {}
      }
      if (Number(section.sequenceNumber) > 0) {
        section.groups.forEach((group: any, groupIndex: number) => {
          const groupName = group.name === '' ? `group${stepKey}` : group.name;
          if (group.canAdd) {
            const a = item[stepKey][groupName];
            Object.values(a).flat().forEach((obj: any) => {
              termsheetData['sections'][`${section.stepname}`][`${obj.key}`] = obj?.value
            })
          } else {
            group.fields.forEach((field: any, fieldIndex: number) => {
              const groupStep = item[stepKey][groupName].find((key: any) => key.key === field.key);
              if (schemaObj.includes(field.key)) {
                termsheetData['sections'][`${section.stepname}`][field.key] = item[stepKey][groupName].find((key: any) => key.key === field.key)?.value;
              }
              termsheetData['sections'][`${section.stepname}`][`${field.key}`] = groupStep?.value || ''
            })
          }
        })
      }
    })
    return termsheetData
  }

  // package
  function findDynamicKeys(obj: any): any {
    let result: any[] = [];
    for (let key in obj) {
      const value = obj[key];
      if (value && typeof value === 'object') {
        if (value.key && value.hasOwnProperty(value.key)) {
          result.push({
            [value.key]: value[value.key],
          });
        }
        result.push(...findDynamicKeys(value));
      }
    }

    return result;
  }

  const [documentData, setDocumentData] = useState<any>()

  function handleSubmit(item: any, step: number, totalStep: number, location: string) {
    setDocumentData(item);
    const values = findDynamicKeys(item);
    const documentsData: Record<string, string> = Object.assign({}, ...values);
    const termsheetData = getTermsheetData(documentsData, item);
    if (step === totalStep - 1 && location === 'preview') {
      termsheetData.type = docType;
      termsheetData.status = "Draft";
      termsheetData.templateId = Number(templateData.id)
      usePreviewTermsheetMutation.mutate({
        companyId: documentsData.companyName || 0, // company is the companyId
        templateName: documentsData.termsheetName,
        contextVariables: [],
        investorId: parseInt(documentsData?.investorId === null ? '0' : documentsData?.investorId.toString()),
        variablesList: documentsData,
        templateId: Number(templateData.id),
      })
      if (termsheetId) {
        termsheetData.termsheetId = termsheetId
      }
      termsheetData.fileUrl = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
      handleSendDraftTermsheet(termsheetData);
    }
    if (step === totalStep && location === 'submit') {
      let signerData: SignerData[] = [];
      const itemsToHave = ['founder', 'investor', "investor details"]
      Object.entries(item).forEach(([key1, formStateValue]: any) => {
        Object.values(formStateValue).forEach((stepObj: any) => {
          if (!Array.isArray(stepObj)) {
            Object.entries(stepObj).forEach(([key, obj]: any) => {
              let tempObj = {
                name: '',
                designation: '',
                email:'',
                signatureType: '',
                key: ''
              };
              obj.forEach((item: any) => {
                if (itemsToHave.some((ith) => key.match(new RegExp(`^${ith}\\d+$`)))) {
                  let stepName = ''
                  const match = item.key.match(/\d+/);
                  if(item.key.match('founder')){
                    stepName = 'founder'
                  }
                  if(item.key.match('investor')){
                    stepName = 'investor'
                  }
                  if (item.key.toLowerCase().match('name') || item.key.toLowerCase().match('designation')) {
                    if (item.key.toLowerCase().match('name')) {
                      tempObj.name = item[item.key];
                      tempObj.key = `${stepName}${match[0]}`
                    } else if (item.key.toLowerCase().match('designation')) {
                      tempObj.designation = item[item.key];
                      tempObj.key = `${stepName}${match[0]}`
                    }
                    const existingSigner = signerData.find((signer: any) => signer.key === tempObj.key);
                    if (existingSigner) {
                      if (tempObj.name) existingSigner.name = existingSigner.name || tempObj.name;
                      if (tempObj.designation) existingSigner.designation = existingSigner.designation || tempObj.designation;
                    } else {
                      if (tempObj.name || tempObj.designation) {
                        signerData.push(tempObj);
                      }
                    }
                      tempObj = { 
                        name: '',
                        designation: '',
                        email:'',
                        signatureType: '',
                        key: ''
                      };
                  }
                }
              });
            });
          }
        });
      });
      sendTermsheetForSign(documentsData.companyId, signerData, termsheetData)
      console.log("submit function or request will be sent for sign will get called");
    }
  }

  function sendTermsheetForSign(companyId:string, signerData: SignerData[], termsheetData:any) {
    fetchDetails(companyId);
    const obj = {
      file: previewUrl.split(",")[1],
      fileData: {
        fileName: templateName,
        fileType: templateData?.type ? templateData.type : "Document"
      },
      requestParams: {
        requestedBy: jwtDetails.emailId,
        reason: "Sign Please",
        responseUrl: `${invApi}/documentStatusUpdate`,
        companyName: companyDetails?.getCompanyData?.companyData.name
      },
      signerData: signerData
    }
    esignClient.post("/admin/uploadRequestDoc", obj).then((data) => {
        setLoading(false)
        let token = data?.data?.token
        if (!token) {
          token = data?.data?.data?.token
        }
        setESignId(data?.data?.data?.esign_id)
        setIFrameToken(token)
        setSignDialog(!signDialog)
        termsheetData.status = 'Pending Signature'
        termsheetData.eSignId = data?.data?.data?.esign_id
        termsheetData.uploadPdfURL = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath
        if (termsheetId) {
          termsheetData.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheetData)
    }).catch((err) => {
        setLoading(false)
        console.error(err)
    })
  }

  function fetchDetails(id: string) {
    const compKey = {
      input: {
        type: CompanyTypes.Hissa,
        hissaCompanyId: id || '',
      },
    }
    setInput(compKey.input);
  }


  const [input, setInput] = useState<{ type: CompanyTypes; hissaCompanyId: string }>({
    type: CompanyTypes.Hissa,
    hissaCompanyId: '',
  });

  const { data: companyDetails, isLoading, isError, refetch: getCompanyDetailsById } = useGetCompanyWiseDataQuery<
    GetCompanyWiseDataQuery,
    Error
  >(
    graphqlRequestClient,
    { input },
    { enabled: input.hissaCompanyId !== '' }
  );

  useEffect(() => {
    if (input.hissaCompanyId !== '') {
      getCompanyDetailsById();
    }
  }, [input.hissaCompanyId])

  const [packageData, setPackageData] = useState<{
    jsonData: any, templateName: string, templateUrl: string, previewUrl: string, draftData: any
    companyDetails: any[]
  }>({
    jsonData: null,
    templateName: '',
    templateUrl: '',
    draftData: [],
    companyDetails: [],
    previewUrl: ''
  });
  useEffect(() => {
    if (templateData) {
      setPackageData({
        ...packageData,
        jsonData: jsonData,
        templateName: templateData.name,
        templateUrl: templateData.thumbnailURL,
        draftData: initialValues,
        companyDetails: allCompanies,
      });
    };
  }, [templateData]);
  useEffect(() => {
    if (allCompanies) {
      setPackageData((prevState) => ({ ...prevState, companyDetails: allCompanies }))
    }
  }, [allCompanies]);

  useEffect(() => {
    if (query.data?.urlForS3Object && query.data?.urlForS3Object !== packageData.templateUrl) {
      setPackageData((prevState) => ({
        ...prevState,
        templateUrl: query.data?.urlForS3Object || '',
      }));
    }
  }, [query.data?.urlForS3Object]);

  return mutateSendTermsheet !== undefined && mutateSendTermsheet.isSuccess ? (
    <EmailTermsheet
      termsheetId={termsheetId}
      showModal={showShareEmailModal}
      type={docType}
      handleClose={() => setShowShareEmailModal(false)}
    />
  ) : (
    <React.Fragment>
      <div className='card card-body shadow-sm'>
        {jsonData[0].type === 'Termsheet' && (
          <div
            className='border border-dashed border-primary border-1 bg-termsheet-bg rounded my-8 p-9'
            style={{ cursor: 'pointer' }}
          >
            <div
              className='d-flex flex-row '
              onClick={() => {
                useChatBot.setIsOpen(true)
              }}
            >
              <span className='me-6'>
                <img
                  src={'/media/icons/duotune/general/ai-bot.svg'}
                  alt=''
                  className='w-100px h-150px'
                />
              </span>

              <span className='fs-8'>
                <h3 className='fw-bold mb-0'>Newly Introducing! Hissa's new AI Powered Template</h3>
                <div className='text-gray-500 fs-7 pb-2'>
                  This Template is filled using AI, the following fields are detected from our
                  conversation
                </div>
                {aiInits && aiInits !== undefined && (
                  <>
                    <div>The Fields detected via our conversation are</div>

                    <div
                      className='row card-body overflow-auto flex-nowrap my-2 mx-0'
                      style={{ overflowX: 'auto', scrollbarWidth: 'none' }}
                    >
                      {filledValue.map((obj, index) => {
                        if (aiInits[obj] !== '' && aiInits[obj] != 0) {
                          return index <= 2 ? (
                            <span className='border border-dashed border-gray-400 rounded p-4 py-2 bg-white w-auto me-4'>
                              <h6
                                className='m-0 fw-bold pb-1'
                                data-toggle='tooltip'
                                data-placement='top'
                                title={aiInits[obj]}
                              >
                                {aiInits[obj].length > 20
                                  ? aiInits[obj].substring(0, 20) + '...'
                                  : aiInits[obj]}
                              </h6>
                              <h5 className='fw-bold m-0 text-primary pb-0'>
                                {getNameForKey(obj, templateData.jsonData)}

                                <span>
                                  <KTSVG
                                    path='/media/icons/duotune/general/stars.svg'
                                    className='svg-icon-6 ms-2 '
                                  />
                                </span>
                              </h5>
                            </span>
                          ) : (
                            <></>
                          )
                        }
                      })}
                      {filledValue.length > 3 && (
                        <span className='border border-dashed border-gray-400 rounded pt-6 px-6 bg-white w-auto me-3'>
                          <h5 className='text-primary fs-bold d-flex align-items-baseline'>
                            {'+' + (filledValue.length - 3)}
                          </h5>
                        </span>
                      )}
                    </div>

                    <div className='pt-6'>
                      Few of the below fields have not been detected by the AI, please go through
                      the document and fill in the necessary fields.{' '}
                    </div>
                  </>
                )}
              </span>

              <div className='col d-flex justify-content-end align-items-end'>
                <button
                  type='button'
                  className='px-4 rounded border border-1 border-hover-primary border-growth-card bg-transparent h-38px fw-bold text-growth-card cursor-pointer bg-hover-secondary text-hover-primary'
                  onClick={(event: any) => {
                    event.stopPropagation()
                    setIsImportModel(true)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/files/fil021.svg'
                    className='svg-icon-2x text-growth-card'
                  />{' '}
                  Import from Modelling
                </button>
              </div>
            </div>
          </div>
        )}
        {isImportModel && (
          <ImportInvestmentModel
            handleClose={() => {
              setIsImportModel(false)
            }}
            templateData={templateData}
            showModal={isImportModel}
            allCompanies={allCompanies}
            initialValues={initialValues}
          />
        )}
        <h1 className='fs-2x fw-bold mt-3'>Issue Document</h1>
        {templateData && 
          <PackageCreationComponent
            jsonData = {jsonData}
            templateData = {packageData}
            step={1}
            docPreviewUrl = {previewUrl}
            buttonFunction = {handleSubmit}
            fetchDetails={fetchDetails}
          />} 
        {signDialog && (
            <SignatureIframe
              showDialog={signDialog}
              token={iFrameToken}
              handleClose={handleSignPopupClose}
            />
          )
        }
      </div>
    </React.Fragment>
  )
}

export default DocumentIssuePageWrapper
