import jwtDecode from "jwt-decode";

export function checkTokenValidity(jwt: string) {
    const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
    
    if (!jwt) return true;
    const decodedToken = decodeAuthToken(jwt);
    const currentDate = new Date();
    if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
      return false;
    return true;
  }
   
  export function decodeAuthToken(accesstoken: string) {
    const decodedToken = jwtDecode(accesstoken) as {
      xUserToken: string;
      exp: number;
    };
    return decodedToken;
  }