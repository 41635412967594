import { Modal } from 'react-bootstrap'
import { KTSVG } from '../_metronic/helpers'
import { InputBox } from './NumberInputBox'
import { useState } from 'react'

type DeletePopupProps = {
  showModel: boolean
  setShowModel: (value: boolean) => void
  primaryAction?: Function
  secondaryAction?: Function
  loading: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  title?: string
  description?: string
  notes?: string
  confirmText?: string
  currentValue?: string
}
export function DeleteConfirmPopup(props: DeletePopupProps) {
  const [value, setValue] = useState<string>('')
  return (
    <>
      <Modal
        id='kt_modal_add_inv'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px '
        show={props.showModel}
        onHide={() => props.setShowModel(false)}
      >
        <Modal.Body className='p-9'>
          <div className='card mt-4'>
            <div className='card-body p-0'>
              <h2 className='fw-bolder text-gray-heading pb-4 d-flex flex-row'>
                <div className='col-1 mt-4'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='svg-icon-2x text-danger bg-danger-badge p-3 rounded-circle pb-4'
                  />
                </div>
                <span className='col-10 ms-5'>
                  {props.title}
                  <div className='fs-8 pb-6 text-gray-500'>{props.description}</div>
                </span>
              </h2>
              {props.notes && (
                <div className='fs-8 fw-normal pb-4'>
                  <b>Note : </b> {props.notes}
                </div>
              )}
              <div className='fv-row mb-4'>
                <p className='fs-8 fw-bold mb-0'>{props.confirmText}</p>
                <InputBox
                  isDisabled={false}
                  name={value}
                  handleChange={(value: string) => {}}
                  setFieldValue={(value: string) => {
                    setValue(value)
                  }}
                  value={value}
                  type='text'
                />
              </div>
              <div className='d-flex col justify-content-between pt-6'>
                <button
                  className='px-4 rounded border border-1 bg-hover-secondary border-primary bg-transparent h-38px fw-normal text-primary'
                  onClick={() => {
                    props.setShowModel(false)
                    props.secondaryAction && props.secondaryAction()
                  }}
                >
                  <span className='indicator-label'>{props.secondaryButtonText || 'Cancel'}</span>
                </button>
                <button
                  type='submit'
                  className={`px-4 rounded border border-0 border-primary ${
                    props.currentValue !== value ? 'disabled bg-gray-500 ' : 'bg-primary'
                  } h-38px fw-normal text-white `}
                  disabled={props.loading || props.currentValue !== value}
                  onClick={(e) => {
                    props.primaryAction && props.primaryAction(e)
                  }}
                >
                  {!props.loading && (
                    <div>
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3 text-white'
                      />
                      {props.primaryButtonText || 'Delete'}
                    </div>
                  )}
                  {props.loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export function DeletePopup(props: DeletePopupProps) {
  return (
    <>
      <Modal
        id='kt_modal_add_inv'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-500px '
        show={props.showModel}
        onHide={() => props.setShowModel(false)}
      >
        <Modal.Body className='p-9'>
          <div className='card'>
            <div className='card-body p-0'>
              <h2 className='fw-bolder text-gray-heading pb-4 d-flex flex-row'>
                <div className='col-1 mt-4'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen027.svg'
                    className='svg-icon-2x text-danger bg-danger-badge p-3 rounded-circle pb-4'
                  />
                </div>
                <span className='col-10 ms-5'>
                  {props.title}
                  <div className='fs-8 pb-6 text-gray-500'>{props.description}</div>
                </span>
              </h2>
              <div className='fs-5 pb-6'>{props.description}</div>
              {props.notes && (
                <div className='fs-8 fw-normal pb-6'>
                  <b>Note : </b> {props.notes}
                </div>
              )}
              <div className='d-flex col justify-content-between pt-6'>
                <button
                  className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                  onClick={() => {
                    props.setShowModel(false)
                    props.secondaryAction && props.secondaryAction()
                  }}
                >
                  <span className='indicator-label'>{props.secondaryButtonText || 'Cancel'}</span>
                </button>
                <button
                  type='submit'
                  className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                  disabled={props.loading}
                  onClick={(e) => {
                    props.primaryAction && props.primaryAction()
                  }}
                >
                  {(!props.loading && props.primaryButtonText) || 'Delete'}
                  {props.loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
