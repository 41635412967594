import {
  AllTransactionsType,
  CompanyMetadataType,
  CompanyObject,
  CompanyTypes,
  InvestorObject,
  TransactionObject,
} from '../../../../generated/graphql'
import { DonutChartType, DonutChartWrapper } from '../../portfolio/components/DonutChartWrapper'
import { CompanyCardType, CompanyDataCard } from '../components/CompanyDataCard'
import { CompanyGrowthCard } from '../components/chartComponents/CompanyGrowthCard'
import { CompanyHeaderCard } from '../components/CompanyHeader/CompanyHeader'
import { InvestorParameters, calculateInvestorParameters } from '../utils/investmentCalculations'
import { HeaderCard } from '../../portfolio/components/HeaderCard'
import { CardType } from '../../portfolio/components/PortfolioHeaderCards'
import { CompanyTransactionAGTable } from '../components/CompanyTransactionAGTable'
import { useEffect, useState } from 'react'
import FilterMenuView, {
  FilterSelectedView,
  FilterTagValue,
} from '../../portfolio/components/FilterMenu'
import { KTSVG } from '../../../../_metronic/helpers'
import _ from 'lodash'
import { filterData } from '../../portfolio/components/utils'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { ManualGrowthCard } from '../components/ManualGrowthCard'
import { AddIndirectTransactionModal } from '../../manual-investments/components/AddAdditionalIndirectTransaction/AddIndirectTransactionModal'
import { AddDirectTransactionModal } from '../../manual-investments/components/AddAdditionalDirectTransaction/AddDirectTransactionModal'
import { IndirectCompanyTransactionAGTable } from '../components/AgGridTableComponents/IndirectCompanyTransactionAGTable'
import { AddTransactionPopup } from '../../portfolio/components/AddTransactionPopUp'
import { ValuationDetailsAGTable } from '../components/AgGridTableComponents/ValuationDetailsAGTable'
import EditCompanyDetailsModal from '../../manual-investments/components/EditCompanyDetails/EditCompanyDetailsModal'
import { formatDate } from '../../../../utils/FormatDate'
import { useCompanyStageStore } from '../../../../store/CompanyStageStore'
import { CompanyMetadata } from '../components/CompanyMetadata'

type Props = {
  companyId: string
  companyData: AllTransactionsType
  allPrivateCompanies: string[]
}

export type InvestorListType = {
  investorName: string
  investorId: string
}

function HissaCompanySummaryPage({ companyId, companyData, allPrivateCompanies }: Props) {
  const [data, setData] = useState(companyData)
  const [dataToggle, setDataToggle] = useState(
    data.transactionData && data.transactionData?.length > 0 ? 'investments' : 'indirectInvestments'
  )
  const companyStageStore = useCompanyStageStore()

  useEffect(() => {
    companyStageStore.setCompanyStage(companyData?.companyData?.companyStage!)
  }, [data])

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [transactionType, setTransactionType] = useState<string>('direct')
  const [showDirectModal, setShowDirectModal] = useState<boolean>(false)
  const [showIndirectModal, setShowIndirectModal] = useState<boolean>(false)
  const companyDetails = data.companyData as CompanyObject
  const investorDetails = data.investorData as unknown as InvestorObject[]
  const [invCalculations, setInvCalculations] = useState(calculateInvestorParameters(data))
  const [valuationDate, setValuationDate] = useState<string>('')

  const [card1, setCard1] = useState(generateHeaderCards(invCalculations, 1))
  // const [card2, setCard2] = useState(generateHeaderCards(invCalculations, 2))
  const [filteredTagValues, setFilteredTagValues] = useState<FilterTagValue[]>([])
  const handleFilterSelection = (filterTagValues: FilterTagValue[]) => {
    setFilteredTagValues(_.cloneDeep(filterTagValues))
    const dataDeepClone = _.cloneDeep([companyData]) as AllTransactionsType[]
    const filteredData = filterData(dataDeepClone, filterTagValues)
    setData(_.cloneDeep(filteredData[0]))
    const updateInvCalculations = calculateInvestorParameters(filteredData[0])
    setInvCalculations(updateInvCalculations)
    const updateCard1 = generateHeaderCards(updateInvCalculations, 1)
    // const updateCard2 = generateHeaderCards(updateInvCalculations, 2)
    setCard1(updateCard1)
    // setCard2(updateCard2)
  }

  function invSelection() {
    setDataToggle('investments')
  }
  function indirectInvSelection() {
    setDataToggle('indirectInvestments')
  }
  function valuationSelection() {
    setDataToggle('valuation')
  }

  const investors: InvestorListType[] = []
  companyData.investorData &&
    companyData.investorData?.forEach((investor) => {
      if (!investors.find((invObj) => invObj.investorId === investor?.id) && investor?.name) {
        investors.push({ investorName: investor?.name, investorId: investor?.id })
      }
    })
  useEffect(() => {
    MenuComponent.reinitialization()
    setData(companyData)
    handleFilterSelection(filteredTagValues)
    setInvCalculations(calculateInvestorParameters(companyData))
    if (companyData.companyData.latestRaiseDate) {
      setValuationDate(formatDate(companyData.companyData.latestRaiseDate, 'dd-MMM-yyyy'))
    }
    if (companyData.valuationDetailsData && companyData.valuationDetailsData.length > 0) {
      const latestValuation = companyData.valuationDetailsData.find((val) => val?.isDefault)
      if (latestValuation) {
        setValuationDate(formatDate(latestValuation?.valuationDate, 'dd-MMM-yyyy'))
      }
    }
  }, [companyData])

  useEffect(() => {
    if (transactionType === 'direct') {
      setShowDirectModal(true)
      setShowIndirectModal(false)
    } else if (transactionType === 'indirect') {
      setShowIndirectModal(true)
      setShowDirectModal(false)
    }
  }, [transactionType, isModalOpen])

  const companyDetailsData = []
  const companyMetaData = data.companyData.companyMetadata || []
  let revenue = undefined
  let year = undefined
  if (companyDetails.financialData?.data && companyDetails.financialData?.data?.length > 0) {
    const yearsData = companyDetails.financialData.data.filter(
      (data) => data?.timelineType?.toLowerCase() === 'year'
    )

    if (yearsData && yearsData.length > 0) {
      yearsData.sort((a: any, b: any) => Number(b.year) - Number(a.year))
      revenue = yearsData[0]?.data.totalRevenue
      year = yearsData[0]?.year
    }
    if (revenue) {
      companyDetailsData.push({
        name: `Revenue (${year})`,
        value: Number(revenue),
        gainOrLoss: 0,
        hasCurrency: true,
      })
    }
  }

  if (companyDetails?.cin) {
    companyDetailsData.push({
      name: 'CIN',
      value: companyDetails.cin,
      gainOrLoss: 0,
      hasCurrency: false,
    })
  }

  companyDetailsData.push(
    ...[
      {
        name: 'Latest Valuation',
        value: Number(companyDetails.valuation),
        gainOrLoss: 0,
        hasCurrency: true,
      },
      {
        name: 'Valuation Date',
        value: valuationDate,
        gainOrLoss: 0,
        hasCurrency: false,
      },
      {
        name: 'Number of FDB Shares',
        value: Number(companyDetails.totalFdbShares),
        gainOrLoss: 0,
      },

      {
        name: 'Current Share Price',
        value: Number(companyDetails.sharePrice),
        gainOrLoss: 0,
        hasCurrency: true,
      },
      {
        name: 'Latest Raise',
        value: Number(companyDetails.latestRaise),
        gainOrLoss: 0,
        hasCurrency: true,
      },
      {
        name: 'Latest Raise Date',
        value: formatDate(
          companyDetails.latestRaiseDate || new Date().toISOString(),
          'dd-MMM-yyyy'
        ),
        gainOrLoss: 0,
        hasCurrency: false,
      },
      {
        name: 'Currency',
        value: companyDetails.companyCurrency || 'INR - ₹',
        gainOrLoss: 0,
        hasCurrency: false,
      },
      {
        name: 'Number of Rounds',
        value: Number(companyDetails.noOfRounds),
        gainOrLoss: 0.3,
      },
    ]
  )

  return (
    <>
      {/* Filter */}
      <div className='row mb-9'>
        <div className='col-12 d-flex flex-row justify-content-end'>
          {/* {data && data.companyData.type !== CompanyTypes.Hissa && ( */}
          <span className='me-4'>
            <button
              type='button'
              className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal ms-4 text-primary'
              onClick={() => {
                setShowEditModal(true)
              }}
            >
              Edit
            </button>
            {showEditModal && (
              <EditCompanyDetailsModal
                onClosed={() => setShowEditModal(false)}
                showModal={showEditModal}
                hissaVerified={companyDetails.type === CompanyTypes.Hissa ? true : false}
                companyData={companyDetails}
                investorData={investorDetails}
                isIndirect={
                  data.indirectTransactionData && data.indirectTransactionData?.length > 0
                    ? true
                    : false
                }
              />
            )}
          </span>
          {/* )} */}
          {data && data.companyData.type !== CompanyTypes.Hissa && (
            <span>
              <button
                type='button'
                className={`px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white ${
                  investors.length > 1 ? 'me-4' : ''
                }`}
                onClick={() => {
                  setShowModal(true)
                }}
              >
                Add Transaction
              </button>

              {showModal && (
                <AddTransactionPopup
                  transactionType={transactionType}
                  showModal={showModal}
                  handleClose={() => {
                    setShowModal(false)
                    setIsModalOpen(false)
                  }}
                  setTransactionType={(type: string) => setTransactionType(type)}
                  isOpen={(isOpen: boolean) => setIsModalOpen(isOpen)}
                />
              )}

              {showIndirectModal && isModalOpen && (
                <AddIndirectTransactionModal
                  showModal={showIndirectModal}
                  handleClose={() => {
                    setIsModalOpen(false)
                    setShowIndirectModal(false)
                  }}
                  isCompanyExist={true}
                  companyName={companyDetails.name}
                  cin={companyDetails.cin || ''}
                  industry={companyDetails.industry || ''}
                  companyCurrency={companyDetails.companyCurrency || ''}
                  companyData={companyDetails}
                  handleSubmit={() => setShowModal(false)}
                  isEdit={false}
                />
              )}
              {showDirectModal && isModalOpen && (
                <AddDirectTransactionModal
                  showModal={showDirectModal}
                  handleClose={() => {
                    setIsModalOpen(false)
                    setShowDirectModal(false)
                  }}
                  isCompanyExist={true}
                  companyData={companyDetails}
                  handleSubmit={() => setShowModal(false)}
                  isEdit={false}
                />
              )}
            </span>
          )}
          {investors.length > 1 && (
            <button
              type='button'
              className={`px-4 rounded border border-1 border-gray-400 bg-transparent h-38px text-gray-800 fw-bold ${
                filteredTagValues.length > 0 ? 'w-550px' : 'w-250px'
              }`}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              {filteredTagValues.length > 0 ? (
                <FilterSelectedView filterTagValues={filteredTagValues} />
              ) : (
                'Filter by Funds'
              )}
              {filteredTagValues.length === 0 && (
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr072.svg'}
                  className='svg-icon-6 svg-icon-gray-500 text-gray-800 ps-2'
                />
              )}
            </button>
          )}

          {data && (
            <FilterMenuView
              allTransactions={[companyData] as AllTransactionsType[]}
              handleFilterSelection={handleFilterSelection}
              isCompanyPage={true}
            />
          )}
        </div>
      </div>
      {/* Company header card */}
      <div className='row mb-9'>
        <div className='col-12'>
          <CompanyHeaderCard
            name={companyDetails.name}
            companyDetails={companyDetails}
            hissaVerified={companyDetails.type === CompanyTypes.Hissa ? true : false}
            companyInfo={''}
            isCurrentCaptable={false}
            isIndirect={
              companyData.indirectTransactionData && companyData.indirectTransactionData?.length > 0
                ? true
                : false
            }
            allPrivateCompanies={allPrivateCompanies}
          />
        </div>
      </div>
      {/* Company data cards for manual companies*/}
      {data && data.companyData.type !== CompanyTypes.Hissa && (
        <div className='row mb-9'>
          <div className='col-12'>
            <div className='row gx-9'>
              <div className='col-12 col-md-4 mb-md-0'>
                <ManualGrowthCard
                  moic={invCalculations.moic}
                  irr={invCalculations.irr}
                  unrealizedValue={invCalculations.unrealizedValue}
                  transactionObjects={
                    data.transactionData ? (data.transactionData as TransactionObject[]) : []
                  }
                />
              </div>
              <div className='col-12 col-md-4 mb-9 mb-md-0'>
                {data.indirectTransactionData && data.indirectTransactionData.length > 0 && (
                  <DonutChartWrapper
                    allTransactions={[data]}
                    header={'Holding Unit'}
                    type={DonutChartType.Units}
                    className={'h-auto'}
                    isIndirect={true}
                  />
                )}
                {(data.indirectTransactionData === null ||
                  (data.indirectTransactionData && data.indirectTransactionData.length === 0)) && (
                  <DonutChartWrapper
                    allTransactions={[data]}
                    header={'Holding Securities'}
                    type={DonutChartType.Shares}
                    className={'h-auto'}
                    isIndirect={true}
                  />
                )}
              </div>
              <div className='col-12 col-md-4'>
                <CompanyDataCard data={getDatCards(companyDetails)} className='h-325px' />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Company data cards for Stage 4 companies */}
      {data && data.companyData.type === CompanyTypes.Hissa && (
        <div className='row gx-9'>
          <div className='col-12 col-md-4 mb-md-0'>
            <CompanyGrowthCard
              moic={invCalculations.moic}
              irr={invCalculations.irr}
              realizedValue={invCalculations.realizedValue}
              holdingPercentage={invCalculations.holdingPercentage * 100.0}
              investmentAmount={invCalculations.investmentAmount}
              unrealizedValue={invCalculations.unrealizedValue}
              transactionObjects={
                data.transactionData ? (data.transactionData as TransactionObject[]) : []
              }
              investorObjects={data.investorData ? (data.investorData as InvestorObject[]) : []}
            />
          </div>
          <div className='col-12 col-md-4 mb-md-0'>
            <div className='d-flex flex-lg-row  gap-9'>
              {card1.map((card: CardType, index: number) => (
                <div className='col'>
                  <HeaderCard
                    className={card.className}
                    value={card.value}
                    name={card.name}
                    iconPath={card.iconPath}
                    indicator={card.indicator}
                    isInteger={card.isInteger}
                    isCurrency={card.isCurrency}
                    key={index}
                  />
                </div>
              ))}
            </div>
            <div className='my-5 py-4'>
              <DonutChartWrapper
                allTransactions={[data]}
                header={'Shareholders'}
                type={DonutChartType.Shareholders}
                className={'min-h-250px mh-250px'}
              />
            </div>
          </div>
          <div className='col-12 col-md-4'>
            {/* <div className='d-flex flex-row gap-9'>
              {card2.map((card: CardType, index: number) => (
                <div className='col'>
                  <HeaderCard
                    className={card.className}
                    value={card.value}
                    name={card.name}
                    iconPath={card.iconPath}
                    indicator={card.indicator}
                    isInteger={card.isInteger}
                    isCurrency={card.isCurrency}
                    key={index}
                  />
                </div>
              ))}
            </div> */}
            <div className='mb-9 mb-md-0'>
              <CompanyDataCard data={companyDetailsData} className='h-630px' />
            </div>
          </div>
        </div>
      )}
      {/* Transaction Ag grid table*/}
      {data && (
        <div className='row mb-4'>
          <div className='col-12'>
            <div className='card mb-5 justify-content-between'>
              <div className='card-header border-0 pt-5 justify-content-start'>
                {data.transactionData && data.transactionData?.length > 0 && (
                  <h2
                    className={` me-6 pt-6 ${
                      dataToggle === 'investments'
                        ? 'border-primary text-gray-800 border-2 border-bottom border-primary fw-bold'
                        : 'text-gray-500 '
                    }`}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={invSelection}
                  >
                    Investments
                  </h2>
                )}
                {data.indirectTransactionData && data.indirectTransactionData?.length > 0 && (
                  <h2
                    className={` me-6 pt-6 ${
                      dataToggle === 'indirectInvestments'
                        ? 'border-primary text-gray-800 border-2 border-bottom border-primary fw-bold'
                        : 'text-gray-500 '
                    }`}
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={indirectInvSelection}
                  >
                    Indirect Investments
                  </h2>
                )}
                <h2
                  className={` me-6 pt-6 ${
                    dataToggle === 'valuation'
                      ? 'border-primary text-gray-800 border-2 border-bottom border-primary fw-bold'
                      : 'text-gray-500 '
                  }`}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={valuationSelection}
                >
                  Valuation Report
                </h2>
                {/* <h2
                  className={` me-6 pt-6 ${
                    dataToggle === 'companyMetadata'
                      ? 'border-primary text-gray-800 border-2 border-bottom border-primary fw-bold'
                      : 'text-gray-500 '
                  }`}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setDataToggle('companyMetadata')}
                >
                  Company Metadata
                </h2> */}
              </div>
              {dataToggle === 'investments' && <CompanyTransactionAGTable data={data} />}
              {dataToggle === 'indirectInvestments' && (
                <IndirectCompanyTransactionAGTable data={data} />
              )}
              {dataToggle === 'valuation' && <ValuationDetailsAGTable data={data} />}
              {/* {dataToggle === 'companyMetadata' && (
                <CompanyMetadata
                  data={companyMetaData as CompanyMetadataType[]}
                  companyDetails={companyDetails}
                  isIndirect={
                    data.indirectTransactionData && data.indirectTransactionData?.length > 0
                      ? true
                      : false
                  }
                />
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function generateHeaderCards(invValues: InvestorParameters, card: number) {
  const cardData: CardType[] = []
  card === 1
    ? cardData.push(
        {
          className: 'col',
          value: invValues.fdbShares!,
          name: 'Number of Shares (FDB)',
          iconPath: '/media/icons/duotune/abstract/abs026.svg',
          indicator: '',
          isInteger: false,
        },
        {
          className: 'col',
          value: invValues.investmentAmount!,
          name: 'Total Invested',
          iconPath: '/media/icons/duotune/abstract/abs034.svg',
          isCurrency: true,
          indicator: '',
          isInteger: false,
        }
      )
    : cardData.push(
        {
          className: 'col',
          value: invValues.latestRaise!,
          name: 'Latest Raise',
          iconPath: '/media/icons/duotune/abstract/abs053.svg',
          indicator: '',
          isCurrency: true,
          isInteger: false,
        },
        {
          className: 'col',
          value: invValues.companyValuation!,
          name: 'Company Valuation',
          iconPath: '/media/icons/duotune/abstract/abs054.svg',
          indicator: '',
          isCurrency: true,
          isInteger: false,
        }
      )

  return cardData
}

function getDatCards(companyDetails: CompanyObject) {
  const cardData: CompanyCardType[] = []
  if (companyDetails?.cin) {
    cardData.push({
      name: 'CIN',
      value: companyDetails.cin,
      gainOrLoss: 0,
      hasCurrency: false,
    })
  }
  cardData.push(
    ...[
      {
        name: 'Company Valuation',
        value: Number(companyDetails.valuation),
        gainOrLoss: 8.3,
        hasCurrency: true,
      },
      {
        name: 'Current Share Price',
        value: Number(companyDetails.sharePrice),
        gainOrLoss: 0.4,
        hasCurrency: true,
      },
    ]
  )
  if (companyDetails.latestRaise && companyDetails.latestRaise > 0)
    cardData.push({
      name: 'Latest Raise',
      value: Number(companyDetails.latestRaise),
      gainOrLoss: 0.4,
      hasCurrency: true,
    })
  if (companyDetails.totalFdbShares && companyDetails.totalFdbShares > 0)
    cardData.push({
      name: 'Number of FDB Shares',
      value: Number(companyDetails.totalFdbShares),
      gainOrLoss: 8.3,
    })
  return cardData
}

export default HissaCompanySummaryPage
