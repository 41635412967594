/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import {
  AllTransactionsType,
  CompanyDataInput,
  GetAllPrivateCaptablesQuery,
  GetAllTransactionsQuery,
  useGetAllPrivateCaptablesQuery,
  useGetAllTransactionsQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { InvestmentGrowthCard } from './components/InvestmentGrowthCard'
import { PortfolioAGTable } from './components/PortfolioAGTable'
import { PortfolioHeaderCards } from './components/PortfolioHeaderCards'
import { DonutChartType, DonutChartWrapper } from './components/DonutChartWrapper'
import { ColumnChart } from './components/chartComponents/ColumnChart'
import FilterMenuView, { FilterSelectedView, FilterTagValue } from './components/FilterMenu'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { filterData } from './components/utils'
import _ from 'lodash'
import { InvestorListType } from '../company/views/HissaCompanySummaryPage'
import { usePortfolioStore } from '../../../store/portfolioFilterDataStore'
import { GlobalContext } from '../store/context/globalContext'
import { getSymbolOfCurrency } from '../../../utils/CurrencyFormatter'

function PortfolioPageWrapper() {
  const { data, isFetched, isLoading } = useGetAllTransactionsQuery<GetAllTransactionsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const { data: allPrivateCompany, isFetched: isPrivateCompanyFetched } =
    useGetAllPrivateCaptablesQuery<GetAllPrivateCaptablesQuery, Error>(graphqlRequestClient, {}, {})
  const allPrivateCompanies: string[] = []
  allPrivateCompany?.getAllPrivateCaptables?.forEach((company: any) => {
    !allPrivateCompanies.includes(company.hissaCompanyId) &&
      allPrivateCompanies.push(company.hissaCompanyId)
  })

  const useFilterStore = usePortfolioStore()
  const [allTransactions, setAllTransactions] = useState<AllTransactionsType[]>([])
  const [filteredTagValues, setFilteredTagValues] = useState<FilterTagValue[]>(
    useFilterStore.selectedFilter || []
  )
  const [counter, setCounter] = useState(0)

  const { state } = useContext(GlobalContext)
  const { userCurrency } = state

  useEffect(() => {
    if (data && data.allTransactions) {
      const getPresentHoldingData = getCurrentCompanyData(
        data.allTransactions as AllTransactionsType[]
      )
      setAllTransactions(getPresentHoldingData as AllTransactionsType[])
      if (filteredTagValues.length > 0) handleFilterSelection(filteredTagValues)
    }
    const companies: CompanyDataInput[] = []
    if (data && data.allTransactions) {
      const allTransactions = data?.allTransactions as AllTransactionsType[]
      allTransactions.forEach((company) => {
        companies.push({
          hissaCompanyId: company.companyData.hissaCompanyId,
          name: company.companyData.name,
          type: company.companyData.type,
          id: company.companyData.id,
        })
      })
      localStorage.setItem('userCompanies', JSON.stringify(companies))
    }

    MenuComponent.reinitialization()
  }, [data])

  const getCurrentCompanyData = (data: AllTransactionsType[]) => {
    const filteredData: AllTransactionsType[] = []
    data.forEach((company) => {
      if (
        company.investorData &&
        company.investorData.length > 0 &&
        company.investorData.some((inv) => !inv?.exited)
      ) {
        filteredData.push(company)
      }
    })
    return filteredData
  }

  const handleFilterSelection = (filterTagValues: FilterTagValue[]) => {
    setFilteredTagValues(_.cloneDeep(filterTagValues))
    let dataDeepClone = _.cloneDeep(
      getCurrentCompanyData(data?.allTransactions as AllTransactionsType[])
    )
    if (
      filterTagValues.length > 0 &&
      (filterTagValues.find((tag) => tag.label === 'All') ||
        filterTagValues.find((tag) => tag.label === 'Exited'))
    ) {
      dataDeepClone = _.cloneDeep(data?.allTransactions) as AllTransactionsType[]
    }
    const filteredData = filterData(dataDeepClone, filterTagValues)
    setAllTransactions(_.cloneDeep(filteredData))
    setCounter(counter + 1)
  }

  const investors: InvestorListType[] = []
  data?.allTransactions &&
    data?.allTransactions?.forEach((transaction) => {
      transaction?.investorData?.forEach((investor) => {
        if (!investors.find((invObj) => invObj.investorId === investor?.id) && investor?.name) {
          investors.push({ investorName: investor?.name, investorId: investor?.id })
        }
      })
    })

  if (isLoading) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  if (isFetched && isPrivateCompanyFetched) {
    return (
      <>
        {/* Filter */}
        <div className='row mb-9'>
          <div className='col-12 d-flex justify-content-end'>
            {
              <button
                type='button'
                className={`px-4 rounded border border-1 border-gray-400 bg-transparent h-38px text-gray-800 fw-bold mw-550px min-w-350px
                }`}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                {filteredTagValues.length > 0 ? (
                  <FilterSelectedView filterTagValues={filteredTagValues} />
                ) : (
                  'Filter by Funds, Sector & Tags'
                )}
              </button>
            }
            {data && (
              <FilterMenuView
                allTransactions={data?.allTransactions as AllTransactionsType[]}
                handleFilterSelection={handleFilterSelection}
              />
            )}
          </div>
        </div>
        {/* Portfolio chart & Investment v/s Growth card */}
        <div className='row mb-9'>
          <div className='col-sm-12 col-md-8 mb-9 mb-md-0 pe-md-4'>
            {allTransactions.length > 0 && (
              <ColumnChart
                className={'h-425px'}
                allTransactions={allTransactions}
                counter={counter}
              />
            )}
          </div>
          <div className='col-sm-12 col-md-4 ps-md-5'>
            {allTransactions.length > 0 && (
              <InvestmentGrowthCard allTransactions={allTransactions} />
              // <RecentCompanies allTransactions={allTransactions} />
            )}
          </div>
        </div>
        {/* Portfolio header cards */}
        <div className='row mb-9'>
          <div className='col-12'>
            <PortfolioHeaderCards allTransactions={allTransactions} />
          </div>
        </div>
        {/* Donut charts */}
        <div className='row mb-9'>
          <div className='col-12'>
            <div className='row gx-9'>
              <div className='col-12 col-md-4 mb-9 mb-md-0'>
                {allTransactions && allTransactions.length > 0 && (
                  <DonutChartWrapper
                    allTransactions={allTransactions}
                    header={`Companies by Valuation (${getSymbolOfCurrency(userCurrency)})`}
                    type={DonutChartType.Portfolio}
                    className={'min-h-250px mh-250px'}
                  />
                )}
              </div>
              <div className='col-12 col-md-4 mb-9 mb-md-0 '>
                {allTransactions && allTransactions.length > 0 && (
                  <DonutChartWrapper
                    allTransactions={allTransactions}
                    header={'Portfolio by Industry'}
                    type={DonutChartType.Industry}
                    className={'min-h-250px mh-250px'}
                  />
                )}
              </div>
              <div className='col-12 col-md-4'>
                {allTransactions && allTransactions.length > 0 && (
                  <DonutChartWrapper
                    allTransactions={allTransactions}
                    header={'Investments by Type'}
                    type={DonutChartType.Stage}
                    className={'min-h-250px mh-250px'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio AG Grid table */}
        <div className='row mb-9'>
          <div className='col-12'>
            <PortfolioAGTable
              allTransaction={allTransactions}
              investors={investors}
              allPrivateCompanies={allPrivateCompanies}
            />
          </div>
        </div>
      </>
    )
  }
  return <></>
}

export default PortfolioPageWrapper
