import { memo, useMemo, useRef, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import {
  CompanyIdRender,
  CompanyNameRender,
  CompanyTypeRender,
} from './InvestorCompaniesAGCoponents'
import {
  AddNewHissaCompaniesForInvestorMutation,
  AddNewHissaCompaniesForInvestorMutationVariables,
  RemoveExistingCompaniesMutation,
  RemoveExistingCompaniesMutationVariables,
  SyncCompanyInput,
  useAddNewHissaCompaniesForInvestorMutation,
  useGetAllInvestmentsQuery,
  useGetAllTransactionsQuery,
  useGetExistingHissaCompaniesQuery,
  useGetInvestorsQuery,
  useGetNewHissaCompaniesQuery,
  useRemoveExistingCompaniesMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'

export type newCompanyData = {
  companyName?: string
  companyId?: string
  companyType?: string
}

type InvestorNewCompaniesProps = {
  data: newCompanyData[]
  selectedInvestorId: number
  isExisting: boolean
}

export function InvestorNewCompaniesTable(props: InvestorNewCompaniesProps) {
  const allCompanies = props.data
  const [loading, setLoading] = useState<boolean>(false)
  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [selectedRows, setSelectedRows] = useState<SyncCompanyInput[]>([])
  const [rowCount, setRowCount] = useState(allCompanies.length)

  const gridApi = useRef<any>(null)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const mutateRemoveCompanies = useRemoveExistingCompaniesMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveExistingCompaniesMutation,
        _variables: RemoveExistingCompaniesMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetExistingHissaCompaniesQuery.getKey({ input: props.selectedInvestorId })
        )
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
        queryClient.invalidateQueries(useGetAllTransactionsQuery.getKey())
        queryClient.invalidateQueries(useGetExistingHissaCompaniesQuery.getKey())
        queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
        setLoading(false)
        Toast('Companies Removed  Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setLoading(false)
      },
    },
    {}
  )
  const mutateAddNewCompanies = useAddNewHissaCompaniesForInvestorMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddNewHissaCompaniesForInvestorMutation,
        _variables: AddNewHissaCompaniesForInvestorMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetNewHissaCompaniesQuery.getKey({ input: props.selectedInvestorId })
        )
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
        queryClient.invalidateQueries(useGetAllTransactionsQuery.getKey())
        queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
        setLoading(false)
        Toast('Companies Added Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setLoading(false)
      },
    },
    {}
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const componentsRegistry = useMemo(
    () => ({
      companyName: memo(CompanyNameRender),
      companyId: memo(CompanyIdRender),
      companyType: memo(CompanyTypeRender),
    }),
    []
  )

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Company Name',
        field: 'companyName',
        cellRenderer: 'companyName',
        // autoHeight: true,
        cellStyle: tableCellStyle,
        initialWidth: 200,
        minWidth: 350,
        sortable: true,
        // suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipCompanyName',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Company Id',
        field: 'companyId',
        cellRenderer: 'companyId',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        minWidth: 350,
        sortable: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipCompanyId',
      },
    ],
    [allCompanies]
  )

  if (props.isExisting) {
    columnDefs.push({
      headerName: 'Company Type',
      field: 'companyType',
      cellRenderer: 'companyType',
      cellStyle: tableCellStyle,
      initialWidth: 200,
      minWidth: 350,
      sortable: true,
      suppressSizeToFit: true,
      menuTabs: ['filterMenuTab'],
      tooltipField: 'tooltipCompanyType',
    })
  }

  const rowData = useMemo(
    () =>
      allCompanies?.map((company) => {
        return {
          companyName: company.companyName,
          companyId: company.companyId,
          companyType: company?.companyType || '',
        }
      }),
    [allCompanies]
  )

  const handleSearch = (e: any) => {
    const filterValue = e.target.value
    if (e.target.value !== '' && e.target.value !== undefined) {
      setIsFilter(true)
    } else {
      setIsFilter(false)
    }
    if (gridApi.current) {
      gridApi.current.setQuickFilter(filterValue)
      const displayedRowCount = gridApi.current.getDisplayedRowCount()
      setRowCount(displayedRowCount)
    }
  }
  const onSelectionChanged = () => {
    if (!isFilter) {
      const selectedRowData = gridApi?.current?.api?.getSelectedRows()
      selectedRowData && setSelectedRows(selectedRowData)
    }
    if (isFilter) {
      const selectedRowData = gridApi?.current?.api?.getSelectedRows()
      const allRows = gridApi.current.getRenderedNodes()
      const filteredRowData = allRows.map((rowNode: any) => rowNode.data)
      selectedRowData?.length < filteredRowData?.length
        ? setSelectedRows(selectedRowData)
        : setSelectedRows(filteredRowData)
    }
  }
  const getFilteredData = () => {
    const filteredData: any[] = []
    gridApi?.current?.forEachNodeAfterFilter((node: any) => {
      filteredData.push(node.data)
    })
    setRowCount(filteredData.length)
    if (allCompanies.length !== filteredData.length) {
      setIsFilter(true)
    } else {
      setIsFilter(false)
    }
    return filteredData
  }

  return (
    <>
      <div className='card p-0 h-375px'>
        <div className='d-flex flex-row border-0 p-9 px-0 py-2'>
          <div className=' align-items-end flex-column'>
            <h1>Companies</h1>

            {rowCount && rowCount > 0 ? (
              <p className='fs-7 text-gray-400'>
                {rowCount} {rowCount > 1 ? 'Companies' : 'Company'}
              </p>
            ) : (
              ''
            )}
          </div>
          <div className='col d-flex flex-row justify-content-end'>
            <span
              className={`d-flex flex-column border border-1 border-secondary-txt bg-transparent h-38px items-center w-full rounded`}
              style={{
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                border: '1',
                borderColor: '#F1F1F2',
                zIndex: 6,
              }}
            >
              <span className='d-flex flex-row'>
                <KTSVG
                  path={'media/icons/duotune/general/gen021.svg'}
                  className='ps-4 svg-icon-2x text-start fs-9 pt-2'
                  svgClassName='text-gray-700'
                />
                <input
                  type='search'
                  placeholder='Search by Company Name'
                  className='form-control w-auto mw-200-px border-0 h-35px rounded'
                  onChange={(e: any) => {
                    gridApi.current.setQuickFilter(e.target.value)
                    handleSearch(e)
                  }}
                />
              </span>
            </span>
            <button
              type='submit'
              value={'Columns'}
              onClick={() => openToolPanel('columns')}
              className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
            >
              <KTSVG
                path='/media/icons/duotune/layouts/lay004.svg'
                className='svg-icon-3 text-secondary-txt'
              />{' '}
              Columns
            </button>
            <button
              value={'Filters'}
              onClick={() => openToolPanel('filters')}
              className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-3 text-secondary-txt'
              />{' '}
              Filter
            </button>
          </div>
        </div>

        <div className='card-body w-100 p-9 pb-4 px-0 pt-0 overflow-auto ag-theme-material h-100'>
          <AgGridReact
            sideBar={agConfigSideBar}
            onGridReady={(params) => {
              gridApi.current = params
            }}
            onFilterChanged={() => {
              getFilteredData()
            }}
            components={componentsRegistry}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            enableCharts={true}
            enableRangeSelection={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            rowSelection={'multiple'}
            rowMultiSelectWithClick={true}
            onSelectionChanged={onSelectionChanged}
            tooltipShowDelay={50}
            tooltipInteraction={true}
            rowClass={'border-right-0 border-left-0 border-dashed'}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: '#f8f8f8' }
              } else {
                return { background: '#ffffff' }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
      <div className='py-3 text-end'>
        <button
          className={`px-4 rounded border border-0 btn-primary  h-38px fw-normal ms-4 text-white ${
            loading || selectedRows?.length === 0
              ? 'disabled bg-primary-light'
              : 'cursor-pointer bg-primary'
          }`}
          type='submit'
          disabled={loading || selectedRows?.length === 0}
          onClick={() => {
            setLoading(true)
            props.isExisting
              ? mutateRemoveCompanies.mutate({
                  input: {
                    investorId: props.selectedInvestorId,
                    newCompanies: selectedRows,
                  },
                })
              : mutateAddNewCompanies.mutate({
                  input: {
                    investorId: props.selectedInvestorId,
                    newCompanies: selectedRows,
                  },
                })
          }}
        >
          {!loading && (
            <span className='indicator-label fw-bold'>
              {props.isExisting ? 'Remove Companies' : 'Add Companies'}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </>
  )
}
