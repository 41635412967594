import { Navigate, Routes, Route, Outlet, useLocation } from 'react-router-dom'
import CompanyProfileWrapper from './CompanyProfileWrapper'
import {
  AddRecentCompaniesMutation,
  AddRecentCompaniesMutationVariables,
  CompanyDataInput,
  CompanyObject,
  CompanyTypes,
  GetCompanyWiseDataQuery,
  useAddRecentCompaniesMutation,
  useGetCompanyWiseDataQuery,
  useGetRecentCompaniesQuery,
} from '../../../../generated/graphql'
import GenericIframeView, { ViewType } from '../components/TabViews/GenericIframeView'
import DocumentsView from '../components/TabViews/DocumentsView'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { Toast } from 'react-bootstrap'
import { ToastType } from '../../../../utils/toast'
import ExitModellingWrapper from './ExitModellingWrapper'
import ReportsPageWrapper from '../../reports/ReportsPageWrapper'
import InvestmentModellingWrapper from './InvestmentModellingWrapper'
import InvestmentModellingStepper from '../components/InvestmentModelling/InvestmentModelStepper'
import { ManageReportsPage } from '../../reports/ManageReportsPage'
import { useCompanyNameStore } from '../../../../store/companyNameStore'
import ExitModellingPage from '../views/ExitModellingPage'
import { TasksViewWrapper } from '../components/TabViews/TasksViewWrapper'
// import DocumentsView from '../components/TabViews/DocumentsView'

function CompanyProfileRouter() {
  const location = useLocation()
  const companyNameStore = useCompanyNameStore()
  const companyType = location.pathname.split('/')[3] as CompanyTypes
  const id = location.pathname.split('/')[4] || ''
  const input: CompanyDataInput = {
    type: companyType,
    hissaCompanyId: id,
    id: companyType !== CompanyTypes.Hissa ? Number(id) : 0,
  }

  const mutateAddRecentCompanies = useAddRecentCompaniesMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddRecentCompaniesMutation,
        _variables: AddRecentCompaniesMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetRecentCompaniesQuery.getKey())
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const { data, isLoading } = useGetCompanyWiseDataQuery<GetCompanyWiseDataQuery, Error>(
    graphqlRequestClient,
    {
      input: input,
    },
    {
      enabled:
        (input.hissaCompanyId !== '' || input.id !== 0) &&
        Object.values(CompanyTypes).includes(companyType),
      onSuccess: (response) => {
        companyNameStore.setCompanyName(response.getCompanyData?.companyData.name || '')
        mutateAddRecentCompanies.mutate({
          input: {
            name: response.getCompanyData?.companyData.name,
            type: input.type,
            hissaCompanyId: input.hissaCompanyId,
            id: input.id,
            logo: response.getCompanyData?.companyData.logo,
            dba: response.getCompanyData?.companyData.dba,
            visitedDateTime: new Date().toISOString(),
          },
        })
      },
    }
  )

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  return (
    <Routes>
      <Route element={<>{<Outlet />}</>}>
        <Route
          path='/summary/:companyType/:id'
          element={
            <>
              <CompanyProfileWrapper />
            </>
          }
        />

        {companyType === CompanyTypes.Hissa && getHissaRoutes(id)}
        {companyType === CompanyTypes.Hissa && (
          <Route
            path='/exitmodelling/:companyType/:id'
            element={
              <>
                <ExitModellingWrapper />
              </>
            }
          />
        )}
        {companyType === CompanyTypes.Hissa && (
          <Route
            path='/exitmodelling/:companyType/:id/:modelId'
            element={
              <>
                <ExitModellingPage />
              </>
            }
          />
        )}
        {companyType === CompanyTypes.Hissa && getHissaRoutes(id)}
        {companyType === CompanyTypes.Hissa && (
          <Route
            path='/investmentmodelling/:companyType/:id'
            element={
              <>
                <InvestmentModellingWrapper
                  companyData={data?.getCompanyData?.companyData as CompanyObject}
                />
              </>
            }
          />
        )}
        {companyType === CompanyTypes.Hissa && (
          <Route
            path='/newmodelling/:companyType/:id/:modelId'
            element={
              <>
                <InvestmentModellingStepper />
              </>
            }
          />
        )}
        <Route
          path='/documents/:companyType/:id'
          element={
            <DocumentsView
              name={data?.getCompanyData?.companyData.name || ''}
              companyId={data?.getCompanyData?.companyData.cin || ''}
              hissaVerified={
                data?.getCompanyData?.companyData.type === CompanyTypes.Hissa ? true : false
              }
              cId={id}
              isIndirect={
                data?.getCompanyData?.indirectTransactionData &&
                data?.getCompanyData?.indirectTransactionData.length > 0
                  ? true
                  : false
              }
              companyData={data?.getCompanyData?.companyData as CompanyObject}
            />
          }
        />
        <Route path='/misreport/:companyType/:id'>
          <Route
            path=':reportId'
            element={
              <ReportsPageWrapper
                companyData={data?.getCompanyData?.companyData as CompanyObject}
              />
            }
          />
          <Route
            path=''
            element={
              <ReportsPageWrapper
                companyData={data?.getCompanyData?.companyData as CompanyObject}
              />
            }
          />
        </Route>
        <Route
          path='/misreport-manage/:companyType/:id'
          element={
            <ManageReportsPage
              companyData={data?.getCompanyData?.companyData as CompanyObject}
              companyInput={input}
            />
          }
        />
        <Route
          path='/tasks/:companyType/:id'
          element={
            <TasksViewWrapper companyData={data?.getCompanyData?.companyData as CompanyObject} />
          }
        />
        {/* {companyType === CompanyTypes.Hissa && getHissaRoutes(id)} */}
        <Route index element={<Navigate to='/company/summary/:companyType/:id' />} />
      </Route>
    </Routes>
  )
}

function getHissaRoutes(companyId: string) {
  const routes = ['captable', 'transactions', 'securities', 'rights', 'financials', 'boardModule']
  const routesView = routes.map((route) => {
    return (
      <Route
        path={`/${route}/HISSA/${companyId}`}
        element={
          <GenericIframeView hissaCompanyId={companyId} type={route.toUpperCase() as ViewType} />
        }
      />
    )
  })
  return <>{routesView}</>
}

export default CompanyProfileRouter
